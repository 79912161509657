import { Settings } from 'http2';

export class User {
    userId: number;
    employeeId: string;
    guid: string;
    email: string;
    userName: string;
    firstName: string;
    lastName: string;
    roleTypeCode: string;
    roleTypeName: string;
    companyCode: string;
    token?: string;
    internal: boolean;
    permissions: string[];
    logonHistoryId: string;
}
