import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '../../services/notification.service';
import { NotificationDetail } from '../models/notification-detail.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-view-notification',
  templateUrl: './view-notification.component.html',
  styleUrls: ['./view-notification.component.scss']
})
export class ViewNotificationComponent implements OnInit, OnDestroy {

  notificationId: number;
  notification: NotificationDetail;

  private unsubscribeAll: Subject<any> = new Subject();

  constructor(public dialogRef: MatDialogRef<ViewNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private notificationService: NotificationService) {
    this.notificationId = data.notificationId;
  }

  ngOnInit() {
    this.notification = new NotificationDetail();
    this.getNotification();
  }

  getNotification() {
    this.notificationService.getNotification(this.notificationId).pipe(takeUntil(this.unsubscribeAll))
    .subscribe(
      data => {
        this.notification = data;
      }
    )
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "center"});
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}