import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Hub } from 'aws-amplify';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'app-authenticated-user',
  templateUrl: './authenticated-user.component.html',
  styleUrls: ['./authenticated-user.component.scss']
})
export class AuthenticatedUserComponent implements OnInit {

  profileId: any;

  message = "Please wait while the page is loading";
  ellipsis = "";
  interval = 500;
  timeout;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.updateEllipsis();

     Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
            this.authenticationService.setUpIdentity().subscribe(
                data => {
                  if (data){
                    if (!data.roleTypeCode) {
                      this.router.navigate(['/core/error']);
                    } else if (data.internal) {
                      this.router.navigate(['/core/dashboard']);
                    } else {
                      this.router.navigate(['/core/preference']);
                    }
                  }else {
                    this.authenticationService.logout();
                  }
                }, error => {
                  // console.log('Error in setting up user identity in SP', error);
                  this.authenticationService.logout();
                }
             );
            break;
        case 'signUp':
            break;
        case 'signOut':
            break;
        case 'signIn_failure':
           break;
        case 'configured': break;
    }
    });
  }

  updateEllipsis() {
    this.timeout = setInterval(() => {
      if (this.ellipsis.length < 3) {
        this.ellipsis = this.ellipsis + ".";
      } else {
        this.ellipsis = "";
      }
    }, this.interval);
  }

  ngOnDestroy() {
    clearInterval(this.timeout);
  }
}
