import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SignInComponent } from './sign-in/sign-in.component';
import { ErrorComponent } from './error/error.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { SharedModule } from '../shared/shared.module';

const appPublicRoutes: Routes = [
];

@NgModule({
  imports: [
    RouterModule.forChild(appPublicRoutes),
    SharedModule
     ],
exports: [RouterModule]
})
export class PublicRoutingModule {

}
