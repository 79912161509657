import { Injectable } from '@angular/core';
import { APIService, HTTP_METHODS } from '../../shared/services/APIService.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { User } from '../../_models/user.model';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {

  apiPath = 'user';
  userDataBS: BehaviorSubject<User>;
  userData: string[] = [];
  currentUser: User;
  employeeId: string;

  constructor(private apiService: APIService) {
    this.userDataBS = new BehaviorSubject<any>([]);
    this.userDataBS.subscribe(data => this.currentUser = data);
    this.currentUser = new User();
  }

  getUser(guid: string): Observable<any> {
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', 'users' + '/' + guid, {}, HTTP_METHODS.GET).subscribe(apiData => {
        subscriber.next(apiData);
      }, error => {
        // console.log(error);
        subscriber.error(error);
      }, () => {
        subscriber.complete();
      });
    });

    return observable;
  }

  loadCurrentUser(attributes: CognitoUserAttribute[]) {
    let userObj = { guid: "", firstName: "", lastName: ""};
    let userObservable = new Observable(subscriber => {
      // Populate the attributes from Idp.
      for (let attribute of attributes) {
        switch (attribute.getName()) {
          case 'custom:etsguid':
            userObj.guid = attribute.getValue();
            break;
          case 'given_name':
            userObj.firstName = attribute.getValue();
            break;
          case 'family_name':
            userObj.lastName = attribute.getValue();
            break;
        }
      };

      this.getUser(userObj.guid).subscribe(
        data => {
          if (data) {
            this.currentUser = data;
          }
          //API is overriding the guid fristname and lastname
          this.currentUser.guid = userObj.guid;
          this.currentUser.firstName = userObj.firstName;
          this.currentUser.lastName = userObj.lastName;

          environment.internal = this.currentUser.internal;
          sessionStorage.setItem("currentUser", JSON.stringify(data));
          this.userDataBS.next(this.currentUser);
          subscriber.next(this.currentUser);
          subscriber.complete();
        }, error => {
          // console.log(error);
          subscriber.error(error);
        }
      );
    });
    return userObservable;
  }

  logOut(sessionId: string): Observable<any> {
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath + '/logout', {}, HTTP_METHODS.POST).subscribe(data => {
        subscriber.next(data);
      }, error => {
        // console.log(error);
        subscriber.error(error);
      });
    });
    return observable;
  }
}