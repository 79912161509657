export interface PopularFeatureItem {
    id: string;
    title: string;
    resourceName: string;
    type: 'item';
    icon?: string;
    url?: string;
    classes?: string;
}

export const popularFeatureRoot: PopularFeatureItem[] = [
    {
        id: 'comm.center',
        title: 'Coming Soon',
        resourceName: 'comm.center',
        type: 'item',
        icon: 'string',
        url: 'rcc',
        classes: 'string'
    }
];
