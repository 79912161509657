import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { AlertNotificationSchedule, Notification } from '../../models/notification.model';
import { NotificationService } from '../../../services/notification.service';
import { SpinnerOverlayService } from '../../../../shared/services/spinner-overlay.service';
import { MatStepper, MatDialog, MatDialogRef } from '@angular/material';
import { PreviewRecipientsDialogComponent } from '../../../components/shared/preview-recipients-dialog/preview-recipients-dialog.component';
import { Router } from '@angular/router';
import { RecipientFiltersService } from '../../../services/recipient-filters.service';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import { FilterCriteriaDialogComponent } from '../../../components/shared/filter-criteria-dialog/filter-criteria-dialog.component';

@Component({
  selector: 'app-notification-review',
  templateUrl: './notification-review.component.html',
  styleUrls: ['./notification-review.component.scss']
})
export class NotificationReviewComponent implements OnInit, OnDestroy {

  currentNotification: Notification;
  @Input() stepper: MatStepper;
  @Output() unsavedData = new EventEmitter<boolean>();
  dialogRef: MatDialogRef<PreviewRecipientsDialogComponent>;
  filterCriteriaDialogRef: MatDialogRef<FilterCriteriaDialogComponent>;
  activeCount: number = 0;
  totalCount: number = 0;
  displayMessageForImprt: boolean = false;
  displayMessageForExtFl: boolean = false;

  scheduleView: any = {};
  contentView: string = '';
  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(public router: Router,
    private notificationService: NotificationService,
    private recipientFiltersService : RecipientFiltersService,
    private dialog: MatDialog, 
    private spinner: SpinnerOverlayService) {
    this._unsubscribeAll = new Subject();
    this.currentNotification = this.notificationService.currentNotification;
  }

  ngOnInit() {
    this.notificationService.currentNotificationBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        //TODO Validate Data before assining
        if (data) {
          this.currentNotification = data;
          this._generateScheduleView();
          this._generateContentView();
        }
      });
    this.notificationService.previewRecipientsBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        if (data) {
          this.activeCount = data.activeCount;
          this.totalCount = data.totalCount;
          this.displayMessageForImprt = true;
        } else {
          this.displayMessageForImprt = false;
        }
      });
    this.recipientFiltersService.previewRecipientsBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
        if (data) {
          this.activeCount = data.activeCount;
          this.totalCount = data.totalCount;
          this.displayMessageForExtFl = true;
        } else {
          this.displayMessageForExtFl = false;
        }
    }, error => {
      // console.log(error);
    });
  }
  private _generateContentView() {
    if (this.currentNotification.type && this.currentNotification.type.code === 'ALERT' && 
      this.currentNotification.notificationContentMetaData && 
      this.currentNotification.notificationContentMetaData.alert ) {
      this.contentView = this.currentNotification.notificationContentMetaData.alert.content;
    }
    if (this.currentNotification.type && this.currentNotification.type.code === 'APREP' && 
      this.currentNotification.notificationContentMetaData && 
      this.currentNotification.notificationContentMetaData.notification ) {
      this.contentView = this.currentNotification.notificationContentMetaData.notification.content;
    }
  }
  private _generateScheduleView() {
    if (this.currentNotification.type && this.currentNotification.type.code === 'ALERT' && this.currentNotification.schedule) {
      this.scheduleView = {
        dateTimeVal: this.currentNotification.schedule.scheduleDateTime
      }

    }
    if (this.currentNotification.type && this.currentNotification.type.code === 'APREP' && this.currentNotification.repSchedule) {
      const arrStart = this.currentNotification.repSchedule.startDateTime.split(' ');
      const arrEnd = this.currentNotification.repSchedule.endDateTime.split(' ');
      this.scheduleView = {
        start: {
          dateVal: arrStart[0],
          timeVal: `${arrStart[1]} ${arrStart[2]}`,
        },
        end: {
          dateVal: arrEnd[0],
          timeVal: `${arrEnd[1]} ${arrEnd[2]}`,
        },
      }          
    }
  }

  ngOnDestroy(): void {
    if(this._unsubscribeAll){
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }
  }


  async save(){
    this.notificationService.currentNotification.status ={code: "SCHLD", description:"Scheduled"};
    this.notificationService.currentNotification.step=5;

    this.spinner.show();
    const result = await this.notificationService.saveNotification();
    this.spinner.hide();
    if (result) {
      this.notificationService.currentNotificationBS.next(result);

      this.notificationService.previewRecipientsBS.next(null);
      this.recipientFiltersService.previewRecipientsBS.next(null);        
      this.router.navigate(['/core/raterCommCenter/notifications/confirmation']);
    }
  }

  previewRecipients() {
    const filterId = this.currentNotification.recipients && this.currentNotification.recipients.existingFilters ? this.currentNotification.recipients.existingFilters.id : 0;
    this.dialogRef = this.dialog.open(PreviewRecipientsDialogComponent, {
      disableClose: true,
      data: {
        notificationId: this.currentNotification.id,
        filterId
      }
    });  
  }

  showFilterCreteria() {
    const filterId = this.currentNotification.recipients && this.currentNotification.recipients.existingFilters ? this.currentNotification.recipients.existingFilters.id : '';
    if (filterId) {
      this.filterCriteriaDialogRef = this.dialog.open(FilterCriteriaDialogComponent,{
        width: "25%",
        disableClose: true,
        data: {
          notificationId: this.notificationService.currentNotification.id,
          filterId
        }
      });  
    }
  }
}