import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dash-analytics-box',
    templateUrl: './dash-analytics-box.component.html',
    styleUrls: ['./dash-analytics-box.component.scss']
})
export class DashAnalyticsBoxComponent implements OnInit {
    public dataDashAnalytics: any[] = [
        {
            "label": "Communication Center",
            "valLabel": "total",
            "val1": 67,
            "val1Label": "Alerts",
            "val2": 14,
            "val2Label": "Messages"
        },
        {
            "label": "Alerts",
            "valLabel": "total",
            "val1": 17000,
            "val1Label": "SMS",
            "val2": 14000,
            "val2Label": "Voice"
        },
        {
            "label": "Email Performance",
            "valLabel": "total",
            "val1": 40000,
            "val1Label": "Read",
            "val2": 14000,
            "val2Label": "Bounced"
        },
        {
            "label": "Rater/Reader Opt-in",
            "valLabel": "total",
            "val1": 4000,
            "val1Label": "SMS",
            "val2": 7000,
            "val2Label": "Voice"
        }
    ];
    constructor() { }

    ngOnInit() {
    }

    writeNumber(valArr) {
        let rVal = '';
        let cVal = null;
        if (valArr.length > 1) {
            cVal = valArr[0] + valArr[1];
        } else {
            cVal = valArr[0];
        }
        if (cVal > 999) {
            rVal = String(Math.floor(cVal / 1000)) + 'k';
        } else {
            rVal = String(cVal);
        }

        return rVal;
    }
}

