import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatInput, MatTableDataSource, MatDialog, MatDialogRef } from "@angular/material";
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from "@angular/forms";
import { OutreachActivityService } from "../../../services/outreach-activity.service";
import { OutreachActivityRow } from "../../models/outreach-activity.model"
import { PreviewRecipientsDialogComponent } from '../../../components/shared/preview-recipients-dialog/preview-recipients-dialog.component';
import { RecipientDataComponent } from '../../recipient-data/recipient-data.component';
import { element } from 'protractor';
import {ConfirmationDialogComponent} from "../../../../core/components/shared/confirmation-dialog/confirmation-dialog.component";
import { Router } from '@angular/router';
import { ViewNotificationComponent } from '../../view-notification/view-notification.component';
import { ExpireNotificationComponent } from '../../expire-notification/expire-notification.component';
import { NotificationService } from '../../../../core/services/notification.service';

@Component({
  selector: "app-outreach-activity-table",
  templateUrl: "./outreach-activity-table.component.html",
  styleUrls: ["./outreach-activity-table.component.scss"]
})
export class OutreachActivityTableComponent implements OnInit {
  @Input() displayedColumns: any[] = ["name","type","communicationMethod","status","lastModifiedDateTime","createdBy","actions"];
  public keyword: string;
  public isLoading = true;
  public dataSource = new MatTableDataSource<OutreachActivityRow>([]);
  //filterBlankSecret is used to reset the filter, filter accepts a string, and empty string is falsey, used to refilter.
  //There is likely a better way, need to refactor
  private filterBlankSecret = '~!_blank_!~' + Math.floor(Math.random() * 999999999) + '_=blank=_';

  @ViewChild('keywordInput', {read: MatInput, static: false}) keywordInput: MatInput;
  @ViewChild('fromInput', {read: MatInput, static: false}) fromInput: MatInput;
  @ViewChild('toInput', {read: MatInput, static: false}) toInput: MatInput;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  // get fromDate() { return this.filterForm.get('fromDate').value; }
  // get toDate() { return this.filterForm.get('toDate').value; }

  message: any;

  public filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    keyword: new FormControl()
  });

  constructor(private outreachActivityService: OutreachActivityService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              private router: Router,
              private notificationService: NotificationService
    ) {

  }

  ngOnInit() {
    this.isLoading = true;
    this.getOutreachActivity();
    this.dataSource.sort = this.sort;
    // sort case-insensitive
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] != null ? data[sortHeaderId].toLowerCase() : data[sortHeaderId];
    this.initFilterPredicate()
  }

  getOutreachActivity() {
    this.outreachActivityService.getList().subscribe(data => {
      this.isLoading = false;
      // this.dataSource = new MatTableDataSource<OutreachActivityRow>(data);
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
    },(error) => {
      this.isLoading = false;
    });
  }

  sortData(e) {
    //
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.keyword = filterValue;
      filterValue = filterValue.trim().toLowerCase();
    } else{
      filterValue = this.filterBlankSecret;
    }

    this.dataSource.filter = filterValue;
  }

  clearFilters() {
    this.keyword = null;
    this.dataSource.filter = null;
    this.filterForm.reset();
  }

  clearDate(input) {
    let filterValue = this.filterBlankSecret;

    if(input == 'to'){
      this.toInput.value = '';
    }

    if(input == 'from'){
      this.fromInput.value = '';
    }

    if(this.keywordInput.value.length){
      filterValue = this.keywordInput.value;
    }

    this.applyFilter(filterValue);
  }

  initFilterPredicate(){
    this.dataSource.filterPredicate = (data, filter:string) => {
      const keys = ['name', 'type', 'communicationMethod', "status", "createdBy", "lastModifiedBy", "fileName", "filterName"];
      let keywordFound = false;
      let dateFound = false;

      const fromDate = this.fromInput.value ? new Date(this.fromInput.value) : false;
      const toDate = this.toInput.value ? new Date(this.toInput.value) : false;
      const hasScheduledDate = data && data.scheduleDate ? true: false;

      if(filter == this.filterBlankSecret){
        filter = '';
      }

      if (filter) {
        for (const key of keys) {
          keywordFound = data[key] ? data[key].toString().trim().toLowerCase().indexOf(filter.toLowerCase()) !== -1 : false;
          if (keywordFound) {
            break;
          }
        }
      }

      if(hasScheduledDate){
        let scheduleDate = new Date(data.scheduleDate);
        scheduleDate.setHours(0,0,0,0);
        if (fromDate && toDate) {
          dateFound = scheduleDate >= fromDate && scheduleDate <= toDate;
        } else if(fromDate){
          dateFound = scheduleDate >= fromDate;
        } else if(toDate){
          dateFound = scheduleDate <= toDate;
        }
      }

      if (filter && (fromDate || toDate)) {
        return (keywordFound && dateFound)
      } else if (filter) {
        return keywordFound
      } else if (fromDate || toDate) {
        return dateFound
      } else {
        return true
      }

    }

  }

  viewNotification(id: number) {
    this.message = null;
    this.dialog.open(ViewNotificationComponent,
      {disableClose: true, data: {notificationId: id}});
  }

  expireNotification(id: number) {
    this.message = null;
    this.dialog.open(ExpireNotificationComponent,
      {disableClose: true, data: {notificationId: id}});
  }

  openRecipientData(id: number, typecode: string, filterName: string) {
    this.message = null;
    this.dialog.open(RecipientDataComponent, {disableClose: true,
       data: {notificationId: id, typecode: typecode, filterName: filterName}});
  }

  previewRecipients(notificationId: number, filterId: number) {
    this.message = null;
    this.dialog.open(PreviewRecipientsDialogComponent, {
      disableClose: true,
      data: {
        notificationId: notificationId,
        filterId: filterId
      }
    });
  }

  cancelNotification(notificationId: number) {
    this.message = null;
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "Cancel Notification",
        includeOkAndCancel: true,
        message: "Are you sure you want to cancel this notification?"
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.outreachActivityService.cancelNotification(notificationId).subscribe(data => {
          this.getOutreachActivity();
        }, (error) => {
          this.message = error.message;
          this.getOutreachActivity();
        });
      }
    });
  }

  editNotification(notificationId: number) {
    this.message = null;
    this._getNotificationData(notificationId)
      .then((result)=> {
        this.notificationService.setDirty(false);
        this.notificationService.currentNotificationBS.next(result);
        this.router.navigate([`/core/raterCommCenter/notifications`]);
    });
  }

  private async _getNotificationData(notificationId: number): Promise<any> {
    return await this.notificationService.editNotification(notificationId);
  }
}
