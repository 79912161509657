import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../core/services/user.service";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    dateTime: Date;
    userLoggedIn: boolean = false;

    public footerContents: object =
        {
            "main": [
                {
                    "label": "ETS",
                    "links": [
                        {
                            "label": "About ETS",
                            "target": "http://ets.org/about"
                        },
                        {
                            "label": "Careers",
                            "target": "http://ets.org/careers"
                        },
                        {
                            "label": "Contact Us",
                            "target": "http://ets.org/contact"
                        },
                        {
                            "label": "Disabilities",
                            "target": "http://ets.org/disabilities"
                        },
                        {
                            "label": "ETS Store",
                            "target": "http://ets.org/store"
                        },
                        {
                            "label": "NEWS: Open Notes",
                            "target": "http://news.ets.org"
                        },
                        {
                            "label": "Research",
                            "target": "http://ets.org/research"
                        }
                    ]
                },
                {
                    "label": "Learning Solutions",
                    "links": [
                        {
                            "label": "Educator Licensure",
                            "target": "http://ets.org/educator_licensure"
                        },
                        {
                            "label": "English Language Learning",
                            "target": "http://ets.org/ella"
                        },
                        {
                            "label": "ETS Accelerate",
                            "target": "http://ets.org/accelerate"
                        },
                        {
                            "label": "Higher Education",
                            "target": "http://ets.org/highered"
                        },
                        {
                            "label": "K-12 Student Programs",
                            "target": "http://ets.org/k12"
                        },
                        {
                            "label": "Workplace \u0026 Training",
                            "target": "http://ets.org/workplace"
                        }
                    ]
                },
                {
                    "label": "Products",
                    "links": [
                        {
                            "label": "GRE Tests",
                            "target": "http://ets.org/gre"
                        },
                        {
                            "label": "HiSET Exam",
                            "target": "http://hiset.ets.org"
                        },
                        {
                            "label": "Praxis Tests",
                            "target": "http://ets.org/praxis"
                        },
                        {
                            "label": "TOEFL",
                            "target": "http://ets.org/toefl_family"
                        },
                        {
                            "label": "TOEIC Tests",
                            "target": "http://ets.org/toeic"
                        },
                        {
                            "label": "View All",
                            "target": "http://ets.org/products"
                        }
                    ]
                }
            ],
            "content": {
                "logo": "assets/img/ETS-Logo-K.png",
                "content": "To advance quality and equity in education by providing fair and valid assessments, research and related services. Our products and services measure knowledge and skills, promote learning and performance, and support education and professional development for all people worldwide."
            },
            "footer": {
                "links": [
                    {
                        "label": "Legal",
                        "target": "http://www.ets.org/legal"
                    },
                    {
                        "label": "Privacy and Security",
                        "target": "http://www.ets.org/privacy"
                    },
                    {
                        "label": "ETS Trademarks",
                        "target": "http://www.ets.org/legal/trademarks/owned"
                    },
                    {
                        "label": "Get Adobe Reader (for PDFs)",
                        "target": "https://get.adobe.com/reader/"
                    }
                ],
                "copyright": "Copyright \u00A9",
                "copyrightBy" : "by Educational Testing Service. All rights reserved. All trademarks are the property of their respective owners."
            },
            "social": [
                {
                    "icon": "fa-twitter",
                    "title": "Follow ETS on Twitter",
                    "target": "https://twitter.com/etsinsights?lang=en"
                },
                {
                    "icon": "fa-linkedin-square",
                    "title": "Follow ETS on LinkedIn",
                    "target": "https://www.linkedin.com/company/educational-testing-service-ets"
                }
            ]
        };

    landingPage: boolean = false;

    constructor(private userService: UserService) {
        this.dateTime = new Date(
            new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
        );
    }

    ngOnInit() {
        this.userService.userDataBS.subscribe(userData => {
            if (userData != null && userData.guid != null) {
                this.userLoggedIn = true;
            }
        });
    }

    ngAfterViewChecked() {
        if (document.getElementsByTagName('body')[0].classList.contains('login')) {
            this.landingPage = true;
        }
    }
}