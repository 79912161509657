import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { LoadGuard } from './_guards/load.guard';
import { SignInComponent } from './public/sign-in/sign-in.component';
import { AuthenticatedUserComponent } from './public/authenticated-user/authenticated-user.component';
import { LoadingComponent } from './public/loading/loading.component';

const routes: Routes = [
   {path: 'login', component: SignInComponent},
   {path: 'spLoginRedirect', redirectTo: 'login'},
   {path: 'authenticated-user', component: AuthenticatedUserComponent},
   {path: 'core',
     loadChildren: () => import('./core/core.module').then(mod => mod.CoreModule) , canLoad : [LoadGuard] , canActivate : [AuthGuard]},
   {path: '', pathMatch: 'full', component: LoadingComponent}
];
@NgModule({
  imports: [RouterModule.forRoot(routes
    // ,{ enableTracing: true } // <-- debugging purposes only
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
