import { RecipientFiltersRow } from './recipient-filters.model';

export class Notification {
    id: number;
    title: string;
    step: number = 1;
    type: Type;
    status: Type = {code:"DRFT", description:"Draft"};
    scheduledByUserId: number;
    notificationContentMetaData: NotificationContentMetaData;
    recipients: NotificationRecipients;    
    filterCriteria: any;    
    schedule: AlertNotificationSchedule;
    repSchedule: RepNotificationSchedule;
}

export class Type {
  code: string;
  description: string;
  toolTip?: string;
  permission?: string;
}


export class NotificationContentMetaData {
  notification: Content;
  message: Message;
  alert?: any
}

export class Content {
  communicationMethod: Type;
  includeEmailFlag: boolean;
  includeBroadCastFlag: boolean;
  content: string;
  subject?: string;
  audioUrl: string;
  preSignedURL: string;
}

export class Message {
  subject: string;
  from: string;
  emailTemplateId: string;
}

export class NotificationRecipients {
  recipientListSource: Type;  
  existingFilters: RecipientFiltersRow;
  fileName: string;
  filterName: string;  
  ccList: string;
  corporateEmailAddressList: string;
  employeeIds: string[];
  uploadCount: number;
  validCount: number;
}

export class AlertNotificationSchedule {
  scheduleOption: Type;
  scheduleDateTime: string;
  scheduledDate?: string;
  scheduledTime?: string;
}

export class RepNotificationSchedule {
  startDateTime: string;
  endDateTime: string;
}

export class NotificationPreview {
  to: string;
	from: string;
  cc: string;
  phoneNumber: string
	subject: string;
	message: string;
}

export const NOTIFICATION_TYPES: Type[] = [
  {code:"ALERT", description:"Alert", toolTip: 'Alerts are short notifications sent as SMS, voice call, broadcast message, and/or email', permission: 'notification.alert.action'},
  {code:"EMAIL", description:"Email Message", toolTip: 'Email messages are communications sent to email only.', permission: 'notification.email.action'},
  {code:"APREP", description:"AP REP Notification", toolTip: 'AP REP Notifications are messages sent to the Rater’s portal only.', permission: 'notification.aprep.action'}
];

