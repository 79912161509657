import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
/**
  model: NotificationModel = {
    type: 'error',
    statusCode: 500,
    title: 'Server Error',
    message: 'Internal Server Error Occured',
    size: 'md',
    showBorder: true
  };
**/
  constructor() {
  }
}
