import { Component, OnInit } from '@angular/core';
import { FeaturesService } from '../../../services/features.service';
import { Router } from '@angular/router';
import { PopularFeatureItem } from '../../../types/popular.features';


@Component({
  selector: 'app-popular-features',
  templateUrl: './popular-features.component.html',
  styleUrls: ['./popular-features.component.scss']
})
export class PopularFeaturesComponent implements OnInit {

  popularFeatureItems: PopularFeatureItem[];

  constructor(private featuresService: FeaturesService,
    private router: Router) {
      this.popularFeatureItems =<PopularFeatureItem[]>[];
    }

  ngOnInit() {
    this.featuresService.popularFeatureItemsBS.subscribe(data =>{
      this.popularFeatureItems = <PopularFeatureItem[]> data;
    });
  }

  gotoPage(page){
    if (page === 'Rater Communication Center') {
      this.router.navigate(['/raterCommCenter']);
    }
    if (page === 'Human Resources Center') {
      this.router.navigate(['/hrCenter']);
    }
  }

}
