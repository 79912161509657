import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { MatStepper } from '@angular/material';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";


@Component({
  selector: 'app-notification-content',
  templateUrl: './notification-content.component.html',
  styleUrls: ['./notification-content.component.scss']
})
export class NotificationContentComponent implements OnInit, OnDestroy {
  selectedNotificationType = '';
  @Input() stepper: MatStepper;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(private notificationService: NotificationService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.notificationService.currentNotificationBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
      data => {
        if(data && data.type && data.type.code ){
          this.selectedNotificationType = data.type.code;
        }
      }, error =>{
        // console.log(error);
      }
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }
}
