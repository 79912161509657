import { Component, OnInit, ViewChild } from '@angular/core';
import { OutreachActivityTableComponent } from '../tables/outreach-activity-table/outreach-activity-table.component';
import { EmailTemplateTableComponent } from '../tables/email-template-table/email-template-table.component'
import { DocumentLibraryTableComponent } from '../tables/document-library-table/document-library-table.component'
import { RecipientFiltersTableComponent } from '../tables/recipient-filters-table/recipient-filters-table.component'
import { RaterCommunicationService } from '../../services/rater-communication.service';
import { NotificationService } from '../../services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rater-communication-center-home',
  templateUrl: './rater-communication-center-home.component.html',
  styleUrls: ['./rater-communication-center-home.component.scss']
})
export class RaterCommunicationCenterHomeComponent implements OnInit {
  @ViewChild(OutreachActivityTableComponent, {static: false}) outreachActivityTable:OutreachActivityTableComponent;
  @ViewChild(EmailTemplateTableComponent, {static: false}) emailTemplateTable:EmailTemplateTableComponent;
  @ViewChild(RecipientFiltersTableComponent, {static: false}) recipientFiltersTable:RecipientFiltersTableComponent;
  @ViewChild(DocumentLibraryTableComponent, {static: false}) documentLibraryTable:DocumentLibraryTableComponent;

  selectedTab: string;
  constructor(private raterCommunicationService: RaterCommunicationService, 
    private router: Router, 
    private notificationService: NotificationService
    ) {
      // 
    }
  ngOnInit() {
    this.selectedTab = this.raterCommunicationService.selectedTab;
    this.selectedTab = this.selectedTab ? this.selectedTab : "outreachActivity";
  }

  clearFilters(pageName : string){
    switch(pageName){
      case'outreachActivity':
      this.outreachActivityTable.clearFilters();
      break;
      case'emailTemplate':
      this.emailTemplateTable.clearFilters();
      break;
      case'recipientFilters':
      this.recipientFiltersTable.clearFilters();
      break;
      case'documentLibrary':
      this.documentLibraryTable.clearFilters();
      break;
    }
  }

  emailTemplateSync() {
    this.emailTemplateTable.emailTemplateSync();
    this.emailTemplateTable.clearFilters();
  }

  onChange(val: string) {
    this.raterCommunicationService.setSelectedTab(val);
  }

  createNotification() {  
    this.notificationService.setDirty(false);
    this.notificationService.currentNotificationBS.next(null);
    this.router.navigate([`/core/raterCommCenter/notifications`]);
  }
}