import { Component, Input, OnInit } from '@angular/core';
import { MatStepper } from '@angular/material';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent implements OnInit {
  @Input() stepper: MatStepper;

  constructor() { }

  ngOnInit() {
  }

}
