import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationItem , NAVIGATIONROOTDATA} from '../../core/types/navigation.type';
import { UserService } from '../../core/services/user.service';

@Injectable({providedIn: 'root'})
export class NavigationService {

  navigationItemsBS: BehaviorSubject<NavigationItem[]>;
  navigationRoot: NavigationItem[];

  constructor(private userService: UserService){
    this.navigationItemsBS = new BehaviorSubject([]);
    this.navigationRoot = [];
    this.userService.userDataBS.subscribe(data => {
      if(data != null) {
        this.dataFilter(data);
        this.navigationItemsBS.next(this.navigationRoot);
      }
    });
  };

  pushNavigationItems(navigationItems: NavigationItem[]){
    this.navigationItemsBS.next(navigationItems);
  }

  dataFilter(apiData: any) {
    NAVIGATIONROOTDATA.forEach(popular => {
       if(apiData.permissions != null){
        if (apiData.permissions.includes(popular.resourceName)) {
          this.navigationRoot.push(popular);
        }
      }
     })
  }

}
