import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { NavigationService } from "../../services/navigation.service";

@Component({
  selector: "app-left-navigation",
  templateUrl: "./left-navigation.component.html",
  styleUrls: ["./left-navigation.component.scss"]
})
export class LeftNavigationComponent implements OnInit {
  navigationItems: any;
  dateTime: Date;
  navigationToggleClass: string = "minimize";
  isCollapsed = true;
  @Output() collapseEvent = new EventEmitter();

  constructor(private navigationService: NavigationService) {
    setInterval(() => {
      this.dateTime = new Date(
        new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
      );
    }, 1000);
  }

  ngOnInit() {
    this.collapseEvent.emit(this.isCollapsed);
    this.navigationService.navigationItemsBS.subscribe(data => {
      data = data.map(row => {
        if (row.url) {
          row.url = this.updateNavLinks(row.url);
        }
        return row;
      });
      this.navigationItems = data;
    });
  }

  toggleNavigationBar(close: string) {
    if (close == "close") {
      this.isCollapsed = true;
    } else {
      this.isCollapsed = !this.isCollapsed;
    }
    this.collapseEvent.emit(this.isCollapsed);
  }

  navigationClick(){
    this.toggleNavigationBar('close');
  }

  updateNavLinks(url) {
    if (!url) return false;
    let newUrl;
    switch (url) {
      case "home":
        newUrl = "/core/dashboard";
        break;
      case "rcc":
        newUrl = "/core/raterCommCenter";
        break;
      case "hr":
        newUrl = "/core/hrCenter";
        break;
    }
    return newUrl.length ? newUrl : url;
  }
}
