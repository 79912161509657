import { Resolve, ActivatedRouteSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { DocumentUploadService } from '../../core/services/document-upload.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadDataResolverService implements Resolve <Observable<any>> {
    constructor(private documentUploadService: DocumentUploadService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any>{
        return this.documentUploadService.getDocumentUploadFormData();
    }
    
}