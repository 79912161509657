import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExternalLinkItem, externalLinkRoot } from '../types/external-link';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class ExternalLinkService {

  externalLinkItemsBS: BehaviorSubject<{}>;
  externalLinkRoot: ExternalLinkItem[];

  constructor(private userService: UserService) {
    this.externalLinkItemsBS = new BehaviorSubject({});
    this.externalLinkRoot = [];
    this.userService.userDataBS.subscribe(data => {
      if(data != null) {
        this.dataFilter(data);
        this.externalLinkItemsBS.next(this.externalLinkRoot);
      }
    })
  }

  dataFilter(apiData: any) {
    externalLinkRoot.forEach(ext => {
      if(apiData.permissions != null){
        if (apiData.permissions.includes(ext.resourceName)) {
          this.externalLinkRoot.push(ext);
        }
     }
    });
  }

}
