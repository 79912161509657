import { Component, OnInit } from "@angular/core";
import { OutreachActivityService } from "../services/outreach-activity.service";
import { EmailTemplateService } from "../services/email-template.service";
import { RecipientFiltersService } from "../services/recipient-filters.service";
import { DocumentLibraryService } from "../services/document-library.service";

@Component({
  selector: "app-rater-communication-center",
  templateUrl: "./rater-communication-center.component.html",
  styleUrls: ["./rater-communication-center.component.scss"]
})

export class RaterCommunicationCenterComponent implements OnInit {
  constructor(
    private outreachActivityService: OutreachActivityService,
    private emailTemplateService: EmailTemplateService,
    private recipientFiltersService: RecipientFiltersService,
    private documentLibraryService: DocumentLibraryService
  ) {}

  outreachActivityData: any[];
  emailTemplateData: any[];
  recipientFiltersData: any[];

  ngOnInit() {
    console.log('BUILD VERSION:  View Notification show Scheduel Exp');
  }

  getOutreachActivity() {
    this.outreachActivityService.getList().subscribe(data => {
    });
  }
  getEmailTemplate() {
    this.emailTemplateService.getList().subscribe(data => {
    });
  }
  getRecipientFilters() {
    this.recipientFiltersService.getList().subscribe(data => {
    });
  }
  getDocumentLibrary() {
    this.documentLibraryService.getList().subscribe(data => {
    });
  }
}
