import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogTemplateComponent } from './dialog-template/dialog-template.component';
import { UserService } from '../../services/user.service';
import { UserContact } from '../../../_models/userContact.model';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { PreferenceService } from '../../services/preference.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.scss']
})
export class PreferenceComponent implements OnInit, OnDestroy {

  receiveText: boolean = undefined;
  option: string = "VOICE";
  previousOptIn : boolean = undefined;
  
  userId: number;
  userContact: UserContact;

  peopleSoftLink;

  // for OnDestroy only, 
  // could be regarded as a set of all individual obervables
  private unsubscribeAll: Subject<any> = new Subject();

  constructor(private userService: UserService,
    private preferenceService: PreferenceService,
    private dialog: MatDialog) {
    this.userContact = new UserContact();
  }

  ngOnInit() {
    this.userService.userDataBS.pipe(takeUntil(this.unsubscribeAll)).subscribe(user => {
      // wait for the userData
      if (user.userId) {
        this.userId = user.userId;
        this.getUserContact();
      }
    });
    this.peopleSoftLink = environment.ext.peopleSoftURL;
  }

  selectionChanged() {
    if (!this.receiveText) {
      this.option = 'VOICE';
    }
  }

  getUserContact() {
    this.preferenceService.getUserContactById(this.userId)
    .pipe(takeUntil(this.unsubscribeAll)).subscribe(
      data => {
        this.userContact = data;
        this.getUserPreference();        
      }
    );
  }

  getUserPreference() {
    this.preferenceService.getUserPreferenceById(this.userId)
    .pipe(takeUntil(this.unsubscribeAll)).subscribe(
      data => {
        if (data) {
          this.receiveText = data['receiveText'];
          this.previousOptIn = this.receiveText; 
          this.option = data['option'];
        }
      })
  }

  save() {
    let msg: string;
    if (this.receiveText == undefined) {
      msg = "An opt in or opt out selection is required. Please also choose your preferred mode of communication.";
      this.showAlert(msg);
      return;
    }

    if (this.receiveText && !this.userContact.cellPhone) {
      //msg = "You do not have a valid cell number in your profile. Please update your profile information in PeopleSoft and refresh your page to opt-in to text alerts.";
      //this.showAlert(msg, true);
      this.dialog.open(DialogTemplateComponent,
        { data: { "receiveText": this.receiveText, "messageSent": false, "showMessage": true} });      
      return;
    }

    let messageSent: boolean = undefined;
    this.preferenceService.updatePreference(this.userId, this.receiveText, this.option, this.userContact.cellPhone)
    .pipe(takeUntil(this.unsubscribeAll)).subscribe(
      data => {
        messageSent = data;
        // check if the user changes the opt-in selection
        let showMessage = true; 
        if(this.previousOptIn != undefined && this.previousOptIn == this.receiveText){
          showMessage = false;
        }
        this.dialog.open(DialogTemplateComponent,
            { data: { "receiveText": this.receiveText, "messageSent": messageSent, "showMessage": showMessage} });
        // update data for front end
        this.getUserPreference();
      },
      error => {
        // console.log(error);
      }
    );
  }

  showAlert(msg: string, showLink: boolean = false) {
    this.dialog.open(ConfirmationDialogComponent,
      { data: { "title": "Alert", "message": msg, "includeOkAndCancel": false, "showLink": showLink } });
  }

  refresh(){
    location.reload();
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}