import { Injectable } from '@angular/core';
import { APIService, HTTP_METHODS } from '../../shared/services/APIService.service';
import { Notification, NotificationPreview } from '../rater-communication-center/models/notification.model';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { PreviewRecipientData } from '../rater-communication-center/models/preview-recipients.model';
import { RecipientData, RecipientList } from '../rater-communication-center/models/recipient-data.model';

@Injectable({ providedIn: "root" })
export class NotificationService {

  apiPath = 'notification';
  apiPath_PreviewAudio = 'notification/previewAudio';
  apiPath_SendEmail = 'notification/sendEmail';
  apiPath_SendTextMessage = 'notification/sendTextMessage';
  apiPath_uploadRecipients = 'notification/recipients';
  apiPath_previewRecipients = 'alert';
  currentNotification: Notification;
  previewNotification: NotificationPreview;
  currentNotificationBS: BehaviorSubject<Notification | any>;
  previewRecipientsBS: BehaviorSubject<PreviewRecipientData | any>;

  private _isDirty = false;

  constructor(private apiService: APIService) {
    this.currentNotification = new Notification();
    this.previewNotification = new NotificationPreview();
    this.currentNotificationBS = new BehaviorSubject(null);
    this.previewRecipientsBS = new BehaviorSubject(null);
  }
 
  setDirty(dirtyValue: boolean): void {
    this._isDirty = dirtyValue;
  }
  
  getDirty(): boolean {
    return this._isDirty;
  }

  saveNotification(): Promise<any> {    
    const result = this.apiService.invokeAPINew('repApi', this.apiPath, { body: this.currentNotification }, HTTP_METHODS.POST);
    return result;
  }
  editNotification(notificationId: number): Promise<any> {   
    const url = 'notification' + '/' + notificationId + '/' + 'edit'; 
    const result = this.apiService.invokeAPINew('repApi', url, {}, HTTP_METHODS.GET);
    return result;
  }
  uploadRecipients(): Observable<Notification | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath_uploadRecipients, { body: this.currentNotification }, HTTP_METHODS.POST).
        subscribe(
          data => {
            this.currentNotification = data;
            subscriber.next(data);
          }, error => {
            // console.log(error);
            subscriber.error(error);
          }, () => {
            subscriber.complete();
          });
    });
    return observable;
  }


  previewAudio(): Observable<Notification | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath_PreviewAudio, { body: this.currentNotification }, HTTP_METHODS.POST).
        subscribe(
          data => {
            subscriber.next(data);
          }, error => {
            subscriber.error(error);
          }, () => {
            subscriber.complete();
          });
    });
    return observable;
  }

  sendEmail(notificationPreview: NotificationPreview): Observable<NotificationPreview | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath_SendEmail, { body: notificationPreview }, HTTP_METHODS.POST).
        subscribe(
          data => {
            subscriber.next(data);
          }, error => {
            subscriber.error(error);
          }, () => {
            subscriber.complete();
          });
    });
    return observable;
  }

  sendTextMessage(notificationPreview: NotificationPreview): Observable<NotificationPreview | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath_SendTextMessage, { body: notificationPreview }, HTTP_METHODS.POST).
        subscribe(
          data => {
            subscriber.next(data);
          }, error => {
            subscriber.error(error);
          }, () => {
            subscriber.complete();
          });
    });
    return observable;

  }

  previewRecipients(messageId: number): Observable<PreviewRecipientData | any> | any {    
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath + '/' + messageId + '/' + 'previewRecipients', {}, HTTP_METHODS.GET).
        subscribe(
          data => {
            subscriber.next(data);
            this.previewRecipientsBS.next(data);
          }, error => {
            subscriber.error(error);
          }, () => {
            subscriber.complete();
          });
    });
    return observable;
  }

  getRecipientData(notificationId: number): Observable<RecipientData>{
    return this.apiService.invokeAPI('repApi', this.apiPath + '/' + notificationId + '/recipientData', 
        {}, HTTP_METHODS.GET);
  }

  getRecipientsByKeyword(notificationId: number, keyword: string): Observable<RecipientList[]> {
    return this.apiService.invokeAPI('repApi', this.apiPath + '/' + notificationId + '/recipientData/' + keyword, 
        {}, HTTP_METHODS.GET);
  } 

  getNotification(notificationId: number) {
    return this.apiService.invokeAPI('repApi', this.apiPath + '/' + notificationId, 
        {}, HTTP_METHODS.GET);
  }
  getNotificationNew(notificationId: number) {
    return this.apiService.invokeAPINew('repApi', this.apiPath + '/' + notificationId, 
        {}, HTTP_METHODS.GET);
  }

  updateNotificationExpiry(notificationId: number, scheduleDateTime: string){
    return this.apiService.invokeAPI('repApi', this.apiPath + '/' + notificationId + '/updateNotificationExpiry/' + scheduleDateTime, 
    {}, HTTP_METHODS.PUT);
  }
}
