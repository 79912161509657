import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionsStoreService} from "./permissions-store.service";

@Directive({
  selector: '[appHasPermissions],[appHasNoPermissions]'
})
export class HasPermissionsDirective {

  constructor(private permissionsStoreService: PermissionsStoreService,
              private templateRef: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef) { }

  @Input() set appHasPermissions(requiredPermission) {
    if(this.permissionsStoreService.hasPermission(requiredPermission)){
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }else {
      this.viewContainerRef.clear();
    }
  }

  @Input() set appHasNoPermissions(requiredPermission) {
    if (!this.permissionsStoreService.hasPermission(requiredPermission)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }
}
