import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AmplifyService} from 'aws-amplify-angular';
import {FederatedSignInOptionsCustom} from '@aws-amplify/auth/lib-esm/types';
import {environment} from '../../../environments/environment';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {UserService} from '../../core/services/user.service';
import {PermissionsStoreService} from "../../_directives/permissions-store.service";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private amplifyService: AmplifyService,
              private userService: UserService,
              private permissionStoreService: PermissionsStoreService) {}

  isAuthenticated(): Observable<any>   {
    return new Observable(subscriber => {
      this.amplifyService.auth().currentSession().then(data => {
        this.amplifyService.auth().currentUserPoolUser().then(userData => {
          subscriber.next(true);
          subscriber.complete();
        }).catch(userDataError => {
          subscriber.next(false);
          subscriber.complete();
        });

      }).catch((sessionErr) => {
        subscriber.next(false);
        subscriber.complete();
      });
    });
  }

  setUpIdentity(): Observable<any>{
    return new Observable(subscriber => {
      this.amplifyService.auth().currentSession().then(data => {
        this.amplifyService.auth().currentUserPoolUser().then(cognitoUserData => {
          (<CognitoUser>cognitoUserData).getUserAttributes((err, attributes) => {
            this.userService.loadCurrentUser(attributes).subscribe(
              userData => {
                if (this.userService.currentUser.permissions) {
                  this.permissionStoreService.init(this.userService.currentUser.permissions);
                }
                subscriber.next(this.userService.currentUser);
                subscriber.complete();
              }, userError => {
                subscriber.error(userError);
              }
            );
          });
        }).catch(userDataError => {
          // console.log(userDataError);
          subscriber.error(userDataError);
        });
      }).catch((sessionErr) => {
        // console.log("Session Error ", sessionErr);
        subscriber.error(sessionErr);
      });

    });
  }

  login(){
    let options: FederatedSignInOptionsCustom =
    {customProvider: environment.internal ? environment.int.customProvider : environment.ext.customProvider};

    this.amplifyService.auth().federatedSignIn(options);
  }

  logout() {
    //TODO deleteAll
    sessionStorage.removeItem('currentUser');
    this.amplifyService.auth().signOut();
  }

}
