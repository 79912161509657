import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort } from '@angular/material';
import { NotificationService } from '../../../services/notification.service';
import { RecipientFiltersService } from '../../../services/recipient-filters.service';
import { MatPaginator } from "@angular/material/paginator";
import { PreviewRecipientList } from '../../../rater-communication-center/models/preview-recipients.model';

@Component({
  selector: 'app-preview-recipients-dialog',
  templateUrl: './preview-recipients-dialog.component.html',
  styleUrls: ['./preview-recipients-dialog.component.scss']
})
export class PreviewRecipientsDialogComponent implements OnInit, OnDestroy {

  columnsToDisplay: string[] = ['employeeId', 'name', 'mobileNumber', 'preferredPhoneNumber', 'emailAddress', 'alternateEmailAddress'];
  //dataSource: ResourceDataSource | any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource = new MatTableDataSource<PreviewRecipientList>([]);
  subscription;
  activeCount = 0;
  totalCount = 0;
  filterCriteria: string;

  constructor(public dialogRef: MatDialogRef<PreviewRecipientsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService,
    private recipientFiltersService: RecipientFiltersService) {
  }

  ngOnInit() {
    //this.dataSource = new ResourceDataSource(this.notificationService, this.recipientFiltersService, this.data);
    this.dataSource.sort = this.sort;
    this.getRecipients();
  }

  getRecipients() {
    this.filterCriteria = null;

    if (this.data) {
      if (this.data.filterId) {
        this.subscription = this.recipientFiltersService.previewRecipientsForExistingFilter(this.data.filterId)
          .subscribe(data => {
            this.dataSource.data = data.previewRecipientList;
            this.dataSource.paginator = this.paginator;
            this.activeCount = data.activeCount;
            this.totalCount = data.totalCount;
            this.filterCriteria = data.filterCriteria;
          }, error => {
            // console.log(error);
          });
      } else if (this.data.notificationId) {
        this.subscription = this.notificationService.previewRecipients(this.data.notificationId)
          .subscribe(data => {
            this.dataSource.data = data.previewRecipientList;
            this.dataSource.paginator = this.paginator;
            this.activeCount = data.activeCount;
            this.totalCount = data.totalCount;
          }, error => {
            // console.log(error);
          });
      } else if (this.data.filterVO) {
        this.subscription = this.recipientFiltersService.previewRecipientsForCreateFilter(this.data.filterVO)
          .subscribe(data => {
            this.dataSource.data = data.previewRecipientList;
            this.dataSource.paginator = this.paginator;
            this.activeCount = data.activeCount;
            this.totalCount = data.totalCount;
          }, error => {
            // console.log(error);
          });
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

