export class Substitution {
    id: number;
    //"substitutionName": "LastName",
    substitutionName: string;
    //"substitutionTypeCode": "MERGE",
    substitutionTypeCode: string;
    // "mergeDataCode": "lname"
    mergeDataCode: string;
    templateId: number;

    //constructor() {}
    constructor(id: number, substitutionName: string, substitutionTypeCode: string, mergeDataCode: string, templateId: number) {
        this.id = id;
        this.substitutionName = substitutionName;
        this.substitutionTypeCode = substitutionTypeCode;
        this.mergeDataCode = mergeDataCode;
        this.templateId = templateId; 
    }
}

export class MetaData {
    templateId: number;
    templateMetaDataCategoryType: string;
}

export class TemplateSubstitution {
    code: string;
    description: string;
}

export class TemplateMergeData {
    code: string;
    description: string;
}

export class Process {
    code: string;
    description: string;
}

export class Program {
    code: string;
    description: string;
}

export class Department {
    code: string;
    description: string;
}



