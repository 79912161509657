import {Component, OnInit, Input, OnDestroy, ViewChild} from '@angular/core';
import { NotificationService } from '../../../../services/notification.service';
import { SpinnerOverlayService } from '../../../../../shared/services/spinner-overlay.service';
import { DatePipe } from '@angular/common';
import { MatStepper } from '@angular/material';
import { Type } from '../../../models/notification.model';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {MatCalendar} from "@angular/material";
import {Moment} from "moment";
import * as moment from "moment";
import { NgForm } from '@angular/forms/forms';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-notification-schedule-alert',
  templateUrl: './notification-schedule-alert.component.html',
  styleUrls: ['./notification-schedule-alert.component.scss']
})
export class NotificationScheduleAlertComponent implements OnInit, OnDestroy {
  form: any = {};

  @Input() stepper: MatStepper;

  @ViewChild(MatCalendar,{static:false})
  calendar : MatCalendar<Moment>;

  notificationScheduleTypes: Type[] = [{code:"SN", description:"Send Now"},{code:"SL", description:"Schedule"}];
  minDate: Date = new Date();
  maxDate: Date = new Date();
  selectedDateT: Moment;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(private notificationService: NotificationService, 
              private spinner: SpinnerOverlayService) {
    this._unsubscribeAll = new Subject();
  }

  compareFn(c1: any, c2: any): boolean {
    return JSON.stringify(c1) === JSON.stringify(c2);
  }
  ngOnInit() {
    const curDateTime = new Date();
    const scheduledDate = moment(curDateTime).format('L');
    const scheduledTime = new DatePipe('en-US').transform(curDateTime,'h:mm a');
    this.form = {
      scheduleOptionCode:'SN',
      scheduledDate,  
      scheduledTime,
      scheduleDateTime: scheduledDate + ' ' + scheduledTime,
      error: {}
    };  
    this._initForm(this.form.scheduleOptionCode, curDateTime);
    this.notificationService.currentNotificationBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
      data => {
        if(data && data.schedule){
          this.form.scheduleOptionCode = 'SL';
          const curDateTime = new Date(data.schedule.scheduleDateTime);
          this._initForm(this.form.scheduleOptionCode, curDateTime);
        } 
      }, error =>{
        // console.log(error);
      }
    );  
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }  

  isDateTimeValid() {
    let scheduleTime = this.form.scheduledTime;
    let scheduleDate = this.form.scheduledDate;
    let currentDateTime = new Date();
    let scheduleDateTime = new Date(scheduleDate + " " + scheduleTime);
    if (scheduleDateTime < currentDateTime) {
      return false;
    }

    return true;
  }

  private _verifyFormError(): void {
    this.form.error = {};
    if (this.form.scheduleOptionCode === 'SL') {
      const scheduleDateTime = moment(this.form.scheduleDateTime);
      const now = moment();      
      
      if(scheduleDateTime < now) {
        this.form.error = {
          code: 'INVALID_SCHEDULE',
          message: 'Scheduled time should be in the future.'
        }  
      }
    }
  }

  async save(form: NgForm) {
    let currentuser = JSON.parse(sessionStorage.getItem('currentUser'));

    this._verifyFormError();
    if(!form.valid || !isEmpty(this.form.error)) {
      return;
    }  

    this.notificationService.currentNotification.step = 4;
    this.notificationService.currentNotification.scheduledByUserId = currentuser.userId;

    const scheduleOption = this.notificationScheduleTypes.find(item => item.code === this.form.scheduleOptionCode);
    const schedule: any= {
      scheduleOption,
      scheduleDateTime: this.form.scheduledDate + ' ' + this.form.scheduledTime,
      scheduledDate: this.form.scheduledDate,
      scheduledTime: this.form.scheduledTime,
    };
    this.notificationService.currentNotification.schedule = schedule;

    this.spinner.show();
    const result = await this.notificationService.saveNotification();
    this.spinner.hide();
    if (result) {
      //  save back to currentNotification
      this.notificationService.currentNotification = result;
      this.notificationService.currentNotificationBS.next(result);
      this.stepper.next();
    }  
  }

  onCalendarSelect(event) {
      this.form.scheduledDate = event.format('L');
      this.notificationService.setDirty(true);    
  }

  processDate(event){
    let date = new Date(event.target.value);
    if(!isNaN(date.getTime()) && date.toString() != "Invalid Date"){
     this.calendar.activeDate=moment(date);
    }else{
    }
  }
  private _initForm(scheduleOptionCode: string, scheduleDateTime: Date) {
    this.selectedDateT = moment(scheduleDateTime);
    this.form.scheduledDate = this.selectedDateT.format('L');  
    this.form.scheduledTime = new DatePipe('en-US').transform(new Date(scheduleDateTime),'h:mm a');
    if (scheduleOptionCode === 'SN') {
      this.minDate = new Date();
      this.maxDate = new Date();
    } 
    if (scheduleOptionCode === 'SL') {
      this.minDate = new Date();
      this.maxDate = null;
      
    }
    if(this.calendar){
      this.calendar.activeDate = this.selectedDateT;
    }      
  }

  onSelectionChange(event) {
    if(event && event.value){
      this._initForm(event.value, new Date());
    }

    this.notificationService.setDirty(true);    
  }
  onTimeChanged(event){
    this.form.error = {};
    this.form.scheduleDateTime = this.form.scheduledDate + ' ' + this.form.scheduledTime,

    this.notificationService.setDirty(true);    
  }   
}
