import { Injectable } from "@angular/core";
import {
  APIService,
  HTTP_METHODS
} from "../../shared/services/APIService.service";
import { BehaviorSubject, Observable, from } from "rxjs";
import { Template } from '../rater-communication-center/models/template.model';

@Injectable({
  providedIn: "root"
})
export class EmailTemplateService {
  path = "template/list";
  data: any;
  currentTemplate = {};
  returnMessage: BehaviorSubject<string> = new BehaviorSubject("");

  constructor(private apiService: APIService) { }

  getList(): Observable<any> {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", this.path, {}, HTTP_METHODS.GET)
        .subscribe(
          data => {
            this.data = data;
            subscriber.next(data);
          },
          error => {
            subscriber.error(error.response.data);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }

  sync(): Observable<any> {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", "template/sync", {}, HTTP_METHODS.GET)
        .subscribe(
          data => {
            this.data = data;
            subscriber.next(data);
          },
          error => {
            subscriber.error(error.response.data);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }


  getContentByID(id: number): Observable<any> {
    if (!id) return
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", `template/${id}/content`, {}, HTTP_METHODS.GET)
        .subscribe(
          data => {
            this.data = data;
            subscriber.next(data);
          },
          error => {
            subscriber.error(error.response.data);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }

  saveTemplate(): Observable<any> {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", "template", {body: this.currentTemplate}, HTTP_METHODS.POST)
        .subscribe(
          data => {
            subscriber.next(data);
            if(data.status == 'success'){
              this.returnMessage.next("Changes to the template have been saved");
            }
          },
          error => {
            subscriber.error(error.response.data);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }

  activate(templateId: number): Observable<any> {
    return new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', `template/${templateId}/activate`, {}, HTTP_METHODS.POST)
      .subscribe(
        data => {
          subscriber.next(data);
        },
        error => {
          subscriber.error(error.response.data);
        },
        () => {
          subscriber.complete();
        }
      );
    });
  }

  deactivate(templateId: number): Observable<any> {
    return new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', `template/${templateId}/deactivate`, {}, HTTP_METHODS.POST)
      .subscribe(
        data => {
          subscriber.next(data);
        },
        error => {
          subscriber.error(error.response.data);
        },
        () => {
          subscriber.complete();
        }
      );
    });
  }


}