import { Injectable } from "@angular/core";
import {
  APIService,
  HTTP_METHODS
} from "../../shared/services/APIService.service";
import { Observable } from "rxjs";
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: "root"
})
export class OutreachActivityService {
  path = "notification/list";
  apiPath_editNotification = 'notification/edit';  
  data: any;

  constructor(private apiService: APIService,
    private notificationService: NotificationService) { 
  }

  getList(): Observable<any> {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", this.path, {}, HTTP_METHODS.GET)
        .subscribe(
          data => {
            this.data = data;
            subscriber.next(data);
          },
          error => {
            subscriber.error(error.response.data);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }

  cancelNotification(notificationId: number): Observable<any> {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", "notification/cancel", { body: { notificationId } }, HTTP_METHODS.POST)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          error => {
            subscriber.error(error.response.data);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }
}