import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatInput, MatTableDataSource, MatDialog } from "@angular/material";
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from "@angular/forms";
import { RecipientFiltersService } from '../../../services/recipient-filters.service';
import { RecipientFiltersRow } from "../../models/recipient-filters.model"
import { PreviewRecipientsDialogComponent } from '../../../components/shared/preview-recipients-dialog/preview-recipients-dialog.component';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: 'app-recipient-filters-table',
  templateUrl: './recipient-filters-table.component.html',
  styleUrls: ['./recipient-filters-table.component.scss']
})
export class RecipientFiltersTableComponent implements OnInit {
  @Input() displayedColumns: any[] = ['name', 'criteria', 'inUse', 'lastModifiedDateTime', 'createdBy', 'actions'];
  public keyword: string;
  public isLoading = true;
  public dataSource = new MatTableDataSource<RecipientFiltersRow>([]);
  //filterBlankSecret is used to reset the filter, filter accepts a string, and empty string is falsey, used to refilter.
  //There is likely a better way, need to refactor
  private filterBlankSecret = '~!_blank_!~' + Math.floor(Math.random() * 999999999) + '_=blank=_';


  @ViewChild('keywordInput', {read: MatInput, static: false}) keywordInput: MatInput;
  @ViewChild('fromInput', {read: MatInput, static: false}) fromInput: MatInput;
  @ViewChild('toInput', {read: MatInput, static: false}) toInput: MatInput;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  public filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    keyword: new FormControl()
  });

  private _unsubscribeAll: Subject<any>;

  constructor(private recipientFiltersService: RecipientFiltersService, private dialog: MatDialog) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.isLoading = true;
    this.getRecipientFilters();
    this.initFilterPredicate();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // sort case-insensitive
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] != null ? data[sortHeaderId].toLowerCase() : data[sortHeaderId];
  }

  getRecipientFilters() {
    this.recipientFiltersService.getList()
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(data => {
      this.isLoading = false;
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
    },error => {
      this.isLoading = false;
    });
  }


  sortData(e) {
    // 
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.keyword = filterValue;
      filterValue = filterValue.trim().toLowerCase();
    } else{
      filterValue = this.filterBlankSecret;
    }

    this.dataSource.filter = filterValue;
  }

  clearFilters() {
    this.keyword = null;
    this.dataSource.filter = null;
    this.filterForm.reset();
  }

  clearDate(input) {
    let filterValue = this.filterBlankSecret;

    if(input == 'to'){
      this.toInput.value = '';
    }

    if(input == 'from'){
      this.fromInput.value = '';
    }

    if(this.keywordInput.value.length){
      filterValue = this.keywordInput.value;
    }

    this.applyFilter(filterValue);
  }

  initFilterPredicate(){
    this.dataSource.filterPredicate = (data, filter:string) => {
      const keys = ['name', 'criteria', 'createdBy', 'lastModifiedBy']
      let keywordFound = false;
      let dateFound = false;

      const fromDate = this.fromInput.value ? new Date(this.fromInput.value) : false;
      const toDate = this.toInput.value ? new Date(this.toInput.value) : false;
      const hasModifiedDate = data && data.lastModifiedDateTime ? true: false;

      if(filter == this.filterBlankSecret){
        filter = '';
      }

      if (filter) {
        for (const key of keys) {
          keywordFound = data[key] ? data[key].toString().trim().toLowerCase().indexOf(filter.toLowerCase()) !== -1 : false;
          if (keywordFound) {
            break;
          }
        }
      }


      if(hasModifiedDate){
        let modifiedDate = new Date(data.lastModifiedDateTime);
        modifiedDate.setHours(0,0,0,0);
        if (fromDate && toDate) {
          dateFound = modifiedDate >= fromDate && modifiedDate <= toDate;
        } else if(fromDate){
          dateFound = modifiedDate >= fromDate;
        } else if(toDate){
          dateFound = modifiedDate <= toDate;
        }
      }

      if (filter && (fromDate || toDate)) {
        return (keywordFound && dateFound)
      } else if (filter) {
        return keywordFound
      } else if (fromDate || toDate) {
        return dateFound
      } else {
        return true
      }

    }

  }

  previewRecipients(filterId: number) {
    this.dialog.open(PreviewRecipientsDialogComponent, {
      disableClose: true,
      data: {
        filterId: filterId
      }
    });
  }

  ngOnDestroy(): void {
    if(this._unsubscribeAll){
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }
  }
}