import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Type } from '../models/notification.model';
import { DocumentUploadFormData } from '../models/document-upload-data.model';
import { DocumentLibraryRow } from '../models/document-library.model';
import { DocumentUploadService } from '../../services/document-upload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmationDialogComponent } from '../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { Subject } from 'rxjs';
import { DocumentUploadValidator } from '../../services/document-upload.validator';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})

export class DocumentUploadComponent implements OnInit{
  documentUploadFormGroup: FormGroup;
  documentUploadFormData: DocumentUploadFormData;
  categoryList: Type[];
  saveMessage: any;
  fileToUpload : File;
  _unsubscribeAll: Subject<any>;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private documentUploadService: DocumentUploadService,
    ) {
      this.documentUploadFormData = this.route.snapshot.data.documentUploadFormData;
      this.categoryList = this.documentUploadFormData.categoryList;
      
      this.saveMessage = null;

      
  }

  ngOnInit() {
    //console.log(this.categoryList);
    this.documentUploadFormGroup = this.fb.group({
      title: [
        null, 
        [Validators.required, Validators.minLength(1), Validators.pattern(/^[_]*[a-zA-Z0-9]+[a-zA-Z0-9_\s-]*?$/)],
        [DocumentUploadValidator.createTitleValidator(this.documentUploadService)]
      ],
      description: [null, [Validators.required,Validators.pattern(/^[_]*[a-zA-Z0-9]+[a-zA-Z0-9_\s-,\.;]*?$/)] ],
      category: [null, Validators.required],
      fileName: [null],
      fileInput: [null]
    });
  }



  onBack() {
    
    if( (this.documentUploadFormGroup.get('title').value != null && this.documentUploadFormGroup.get('title').value.trim().length !=0) ||
        (this.documentUploadFormGroup.get('description').value != null && this.documentUploadFormGroup.get('description').value.trim().length !=0) || 
        (this.documentUploadFormGroup.get('category').value != null && this.documentUploadFormGroup.get('category').value.trim().length !=0) ||
        (this.documentUploadFormGroup.value.fileInput != null && this.documentUploadFormGroup.value.fileInput.length !=0)){

          this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            data: {
              title: "Unsaved Data",
              includeOkAndCancel: true,
              message: "Any unsaved data will be lost. Are you sure you want to leave this page? Press 'OK' to continue or 'Cancel' to stay on the current page."
            }
          });

          this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['/core/raterCommCenter']);
            }
          });
    }else{
      this.router.navigate(['/core/raterCommCenter']);
    }

    
  }

  save(){
    this.saveMessage = null;
    if (this.documentUploadFormGroup.valid) {
      
      let vFileName : string = this.documentUploadFormGroup.get('fileName').value;
      let vExtension : string = vFileName.split('.').pop(); // Only file extension
      let vFileBaseName : string = vFileName.split('.').slice(0, -1).join('.'); // FileName without extension
      let dateAndTime : string = new Date().toISOString().split('.').slice(0, -1).join('.'); // Only Data and Time, without Milli Seconds      
      let vS3FileName : string = vFileBaseName + '-' + dateAndTime + '.' + vExtension; // FileName + DateTime + FileExtension
      let vTitle : string = this.documentUploadFormGroup.get('title').value;
      let vCategoryCode : string = this.documentUploadFormGroup.get('category').value;
      let vDescription : string = this.documentUploadFormGroup.get('description').value;
      let vDocumentLibraryRecord : DocumentLibraryRow = new DocumentLibraryRow();
      vDocumentLibraryRecord.title = vTitle;
      vDocumentLibraryRecord.fileName = vS3FileName;
      vDocumentLibraryRecord.description = vDescription;
      vDocumentLibraryRecord.categoryCode = vCategoryCode;

      this.documentUploadService.getUploadPreSignedUrl(vCategoryCode,vS3FileName).subscribe(urlData => {
        if (urlData && urlData.message) {
            this.documentUploadService.uploadFileToS3(urlData.message, this.fileToUpload).subscribe(s3Data => {
                if(s3Data && s3Data.status === 200){
                  this.documentUploadService.saveRecord(vDocumentLibraryRecord).subscribe((saveData : any) => {
                    this.saveMessage = "Document uploaded successfully.";
                    this.documentUploadFormGroup.reset();
                    }, (error : any) => {
                      this.showErrorMessage(error, "Save failed. Please retry.");
                    });
                }
              }, (error : any) => {
                this.showErrorMessage(error, "Document upload failed. Please retry.");
            });
          }
        }, (error : any) => {
          this.showErrorMessage(error, "upload URL generation failed. Please retry.");
      });
    } else {
      this.validateAllFormFields(this.documentUploadFormGroup)
   }
  }

  private showErrorMessage(error : any, message : string ){
    this.saveMessage = message;
    setTimeout(() => {
      this.saveMessage = null;
    }, 2000);
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

 ngOnDestroy(): void {
  if(this._unsubscribeAll){
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
 }
  onFileInput(event): void {
    const fullPath = this.documentUploadFormGroup.value.fileInput;
    if (fullPath) {
          const startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
          let filenameV = fullPath.substring(startIndex);
          if ( filenameV.indexOf('\\') === 0 ||  filenameV.indexOf('/') === 0) {
            filenameV = filenameV.substring(1);
          }
          this.documentUploadFormGroup.patchValue({fileName: filenameV});      
          if(event.target.files && event.target.files.length) {
              const [file] = event.target.files;
              this.fileToUpload = file;
          }
      }
  }
}
