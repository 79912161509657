import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../shared/services/authentication.service';
import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class LoadGuard implements CanLoad {

  constructor(private authenticationService: AuthenticationService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean>|Promise<boolean>|boolean {
    return true;
  }

}
