import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RecipientFiltersService } from '../../../services/recipient-filters.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'app-filter-criteria-dialog',
  templateUrl: './filter-criteria-dialog.component.html',
  styleUrls: ['./filter-criteria-dialog.component.scss']
})
export class FilterCriteriaDialogComponent implements OnInit, OnDestroy {
  filterCriteria: string;

  constructor(public dialogRef: MatDialogRef<FilterCriteriaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private recipientFiltersService: RecipientFiltersService,
    private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.getRecipients();
  }

  getRecipients() {
    if (this.data) {
      if (this.data.filterId) {
        this.recipientFiltersService.previewRecipientsForExistingFilter(this.data.filterId)
          .subscribe(data => {
            if(data.error) {
              console.error('Error ', data.error);
            } else {
              if (data.filterCriteria) {
                this.filterCriteria = data.filterCriteria;
              }
            }
          }, error => {
            // console.log(error);
          });
      } 
    }
  }

  private _parseFilterCriteria(filterCriteria: string) {
    // Program: PRAXIS | Test: Art Content (132) | Level of Experience: All | Highest Role: All | Company: All | State: Florida,Georgia
    const arrCriteria = filterCriteria.split('|');
    return {      
      program: arrCriteria[0].replace('Program:', '').trim(),
      test: arrCriteria[1].replace('Test:', '').trim(),
      experienceLevel: arrCriteria[2].replace('Level of Experience:', '').trim(),
      highestRole: arrCriteria[3].replace('Highest Role:', '').trim(),
      company: arrCriteria[4].replace('Company:', '').trim(),
      state: arrCriteria[5].replace('State:', '').trim(),
    }
  }



  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // 
  }
}
