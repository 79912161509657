import { Component, OnInit } from '@angular/core';
import { ExternalLinkService } from '../../services/external-link.service';
import { ExternalLinkItem } from '../../types/external-link';

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss']
})
export class ExternalLinkComponent implements OnInit {

  externalLinkItems: ExternalLinkItem[] | any;

  constructor(private externalLinkService: ExternalLinkService) {
    this.externalLinkItems =<ExternalLinkItem[]> [];
  }

  ngOnInit() {
    this.externalLinkService.externalLinkItemsBS.subscribe(data => {
      this.externalLinkItems = data;
    });
  }
}
