import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {NotificationService } from '../../services/notification.service'
import { NotificationDetail } from '../models/notification-detail.model';
import { Subject } from 'rxjs';
import {MatCalendar} from "@angular/material";
import { DatePipe } from '@angular/common';
import {Moment} from "moment";
import * as moment from "moment";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-view-notification',
  templateUrl: './expire-notification.component.html',
  styleUrls: ['./expire-notification.component.scss']
})
export class ExpireNotificationComponent implements OnInit, OnDestroy {
  
  notificationId: number;
  notification: NotificationDetail;
  expiryDateTime : string;
  form: any = {};
  minDate: Date = new Date();
  maxDate: Date = null;
  selectedDateT: Moment;

  @ViewChild(MatCalendar,{static:false})
  calendar : MatCalendar<Moment>;
  private unsubscribeAll: Subject<any> = new Subject();

  constructor(public dialogRef: MatDialogRef<ExpireNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private notificationService: NotificationService) {
    this.notificationId = data.notificationId;
  }

  ngOnInit() {
    this.notification = new NotificationDetail();
    this.getNotification();
    this._initForm();
  }
  private _initForm() {
    const curDateTime = new Date();
    this.selectedDateT = moment(curDateTime);
    const scheduledDate = this.selectedDateT.format('L');
    const scheduledTime = new DatePipe('en-US').transform(curDateTime,'hh:mm a');
    this.form = {
      scheduledDate : scheduledDate,  
      scheduledTime : scheduledTime,
      scheduleDateTime: scheduledDate + 'T' + scheduledTime,
    };
    this.form.scheduledDate = this.selectedDateT.format('L');  
    this.form.scheduledTime = new DatePipe('en-US').transform(curDateTime,'hh:mm a');
    if(this.calendar){
      this.calendar.activeDate = this.selectedDateT;
    }      
  }
  getNotification() {
    this.notificationService.getNotification(this.notificationId).pipe(takeUntil(this.unsubscribeAll))
    .subscribe(
      data => {
        this.notification = data;
      }
    )
  }
  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "center"});
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  onCalendarSelect(event) {
    this.form.scheduledDate = event.format('L');
    this.form.scheduleDateTime = this.form.scheduledDate + 'T' + this.form.scheduledTime,
    this.notificationService.setDirty(true);
  }
  onTimeChanged(){
    if(this.form.scheduledTime.length == 7){
      this.form.scheduleDateTime = this.form.scheduledDate + 'T0' + this.form.scheduledTime;
    }else{
      this.form.scheduleDateTime = this.form.scheduledDate + 'T' + this.form.scheduledTime;
    }
    
    this.notificationService.setDirty(true);
  }
  saveChanges(){
    this.expiryDateTime = this.form.scheduleDateTime.replaceAll("/","-");
    console.log(this.expiryDateTime);
    this.notificationService.updateNotificationExpiry(this.notificationId, this.expiryDateTime).pipe(takeUntil(this.unsubscribeAll))
    .subscribe(
      data => {
        this.closeDialog();
      },
      error => { 
        this.closeDialog();
      }
    );    
  }
}