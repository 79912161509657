import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { PublicModule } from './public/public.module';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { MatFormFieldModule } from '@angular/material';
// import { MatInputModule } from '@angular/material/input';
// import { MatButtonModule } from '@angular/material/button';


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        PublicModule,
        CoreModule,
        AmplifyAngularModule,
        FlexLayoutModule,
        // MatFormFieldModule,
        // MatInputModule,
        // MatButtonModule
        // AmplifyModule
    ],
    providers: [
        AmplifyService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
