import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LeftNavigationComponent } from './components/left-navigation/left-navigation.component';
import { GroupItemComponent } from './components/left-navigation/group-item/group-item.component';
import { ItemComponent } from './components/left-navigation/item/item.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MaterialModule } from './material/material.module';
import { ApiSpinnerDialogComponent } from './components/api-spinner-dialog/api-spinner-dialog.component'; // Angular Material Bundle
import { MatDialogRef } from '@angular/material';
import {HasPermissionsDirective} from "../_directives/has-permissions.directive";

//import { PageComponentsModule } from '@ets/page-components';
//import { IndicatorsModule } from '@ets/indicators';


/**
 * Shared Module declares often used components, directives and pipes.
 * Export these declarations so they will be accessible inside any
 * module that imports the SharedModule.Furthermore, if you’re importing
 * modules that make declarations which need to be available across the
 * entire app(like FormsModule, CommonModule, or a 3rd-party modules),
 * just import and export them in the SharedModule.The SharedModule
 * should NOT be providing any services, as these could be instantiated
 * more than once when importing the SharedModule multiple times.
 * Instead, move your singleton services into a new module called CoreModule.
 */

@NgModule({
    declarations: [
        /**
         * Declare often used components, directives and pipes here.
         * Then export these declarations and they will be accessible
         * inside any module that imports the SharedModule.
         */
        LeftNavigationComponent,
        GroupItemComponent,
        ItemComponent,
        FooterComponent,
        HeaderComponent,
        ApiSpinnerDialogComponent,
        HasPermissionsDirective
    ],
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        FlexLayoutModule,
        MaterialModule,
        NgxMaterialTimepickerModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        LeftNavigationComponent,
        GroupItemComponent,
        ItemComponent,
        FooterComponent,
        HeaderComponent,
        MaterialModule,
        NgxMaterialTimepickerModule,
        HasPermissionsDirective
    ],
    entryComponents: [
      ApiSpinnerDialogComponent
    ],
    providers: [{ provide: MatDialogRef, useValue: {} }]
})
export class SharedModule { }
