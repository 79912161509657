import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoginService {

  oamErrorList = [
  {code: 'OAM-1', message: 'An incorrect Username or Password was specified.'},
  {code: 'OAM-2', message: 'An incorrect Username or Password was specified.'},
  {code: 'OAM-3', message: 'Unexpected Error occurred while processing credentials. Please retry your action again!'},
  {code: 'OAM-4', message: 'System error. Please contact the System Administrator.'},
  // tslint:disable-next-line: max-line-length
  {code: 'OAM-5', message: 'Your account has been locked. Please unlock your account by clicking the "Forgot Password" link or call the Help Desk.'},
  {code: 'OAM-6', message: 'The user has already reached the maximum allowed number of sessions. Please close one of the existing sessions before trying to login again.'},
  {code: 'OAM-7', message: 'System error. Please re-try your action. If you continue to get this error, please contact the Administrator.'},
  {code: 'OAM-8', message: 'You entered an invalid username and/or password. Passwords are case sensitive. Make sure your CAPS LOCK key is off. Please try again or click "Forgot Username" or "Forgot Password" links for help.'},
  {code: 'OAM-9', message: 'System error. Please re-try your action. If you continue to get this error, please contact the Administrator.'},
  {code: 'OAM-10', message: 'The password has expired. Please contact the System Administrator.'}];

  public loginErrors: BehaviorSubject<any>;

  public loginParams: BehaviorSubject<any>;

  constructor(){
    this.loginErrors = new BehaviorSubject(null);
    this.loginParams = new BehaviorSubject(null);
  }

  public getMessageByCode(errorCode: string): string {
    let error = this.oamErrorList.filter(e => e.code === errorCode);
    // console.log(error);
   return error[0].message;
  }

}
