import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../shared/services/navigation.service';
import { ExternalLinkService } from '../../services/external-link.service';
import { externalLinkRoot } from '../../types/external-link';
import { FeaturesService } from '../../services/features.service';
import { popularFeatureRoot } from '../../types/popular.features' ;
import { NAVIGATIONROOTDATA } from '../../types/navigation.type';
import { UserService } from '../../services/user.service';
import { combineAll } from 'rxjs/operators';
import { User } from '../../../_models/user.model';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  user: User;

  constructor(private userService: UserService) {}

  ngOnInit() {
    console.log('Dashboard component loaded');
    this.userService.userDataBS.subscribe(userdata =>{
      this.user = userdata;
    });
  }
}
