import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-notification-import-info',
  templateUrl: './notification-import-info.component.html',
  styleUrls: ['./notification-import-info.component.scss']
})
export class NotificationImportInfoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NotificationImportInfoComponent>) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}