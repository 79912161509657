import { Injectable } from '@angular/core';
import {UserService} from "../core/services/user.service";

@Injectable({
  providedIn: 'root'
})
export class PermissionsStoreService {

  private userPermissionsList: string[];

  constructor(private userService: UserService) { }

  init(permissions: string[]){
    if(this.userPermissionsList){
    }else if(permissions){
      this.userPermissionsList = permissions;
   }else{
      // 
    }
  }

  hasPermission(requiredPermissions: string[]): boolean {
    if (this.userPermissionsList && this.userPermissionsList.length > 0) {
      for (let i = 0; i < requiredPermissions.length; i++) {
        let hasPermission = this.userPermissionsList.includes(requiredPermissions[i]);
        if (hasPermission) {
          return hasPermission;
        }
      }
      return false;
    } else {
      return false;
    }
    return true;
  }
}