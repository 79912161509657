import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { SharedModule } from '../shared/shared.module';
import { PublicRoutingModule } from './public-routing.modules';
import { AuthenticatedUserComponent } from './authenticated-user/authenticated-user.component';
import { ErrorComponent } from './error/error.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { EtsIdpLoginComponent } from './ets-idp-login/ets-idp-login.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { LoadingComponent } from './loading/loading.component';


@NgModule({
    declarations: [
        SignInComponent,
        AuthenticatedUserComponent,
        ErrorComponent,
        SignOutComponent,
        EtsIdpLoginComponent,
        LoadingComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        PublicRoutingModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule
    ]
})
export class PublicModule { }
