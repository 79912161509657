import { Injectable } from "@angular/core";
import { APIService, HTTP_METHODS } from "../../shared/services/APIService.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class DocumentLibraryService {
  apiPath_list = "document-library/search/all/null";
  apiPath_status_update = "document-library/status/";
  data: any;

  constructor(private apiService: APIService) {
  }

  getList(): Observable<any> {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", this.apiPath_list, {}, HTTP_METHODS.GET)
        .subscribe(
          data => {
            this.data = data;
            subscriber.next(data);
          },
          error => {
            subscriber.error(error.response.data);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }

  documentStatusUpdate(documentId : number, status : string): Observable<any> {
    let uri = this.apiPath_status_update+documentId+'/'+status;
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", uri, {}, HTTP_METHODS.PUT)
        .subscribe(
          data => {
            this.data = data;
            subscriber.next(data);
          },
          error => {
            subscriber.error(error.response.data);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }
}
