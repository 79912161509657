import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { DocumentUploadService } from './document-upload.service';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
// import { first, map, debounceTime, switchMap, distinctUntilChanged } from 'rxjs/operators';

export class DocumentUploadValidator {

    static createTitleValidator(_service: DocumentUploadService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            return control.valueChanges.pipe(
                debounceTime(300),
                distinctUntilChanged(),
                switchMap(value => _service.titleDuplicateCheck(control.value)),
                map((result) => {
                    // console.log(result);
                   return result && result.status == 'failed' ? {titleAlreadyExists: true} : null;
                }),
            first()
            );
        }
    }
}