import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ExternalLinkComponent } from './components/external-link/external-link.component';
import { CoreRoutingModule } from './core-routing.module';
import { ErrorComponent } from './components/error/error.component';
import { SharedModule } from '../shared/shared.module';
import { CoreComponent } from './core.component';
import { PopularFeaturesComponent } from './components/shared/popular-features/popular-features.component';
import { HumanResourcesCenterComponent } from './components/human-resources-center/human-resources-center.component';
import { PreferenceComponent } from './components/preference/preference.component';
import { DialogTemplateComponent } from './components/preference/dialog-template/dialog-template.component';
import { DashAnalyticsBoxComponent } from './components/dash-analytics-box/dash-analytics-box.component';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { PreviewRecipientsDialogComponent } from './components/shared/preview-recipients-dialog/preview-recipients-dialog.component';
import { RaterCommunicationCenterModule } from './rater-communication-center/rater-communication-center.module';
import { MatButtonToggleModule } from '@angular/material';
import { FilterCriteriaDialogComponent } from './components/shared/filter-criteria-dialog/filter-criteria-dialog.component';

/**
 * A Core Module contains code that will be used to instantiate
 * your app and load some core functionality. In the Core Module,
 * we commonly place our singleton services and modules that will
 * be used across the app but only need to be imported once.
 * Examples are Authentication Service or LocalStorage Service,
 * but also modules like HttpClientModule, <FeatureModule>.forRoot(…),
 * The CoreModule is then imported into the AppModule.
 */
@NgModule({
    declarations: [CoreComponent, DashboardComponent, ErrorComponent, ExternalLinkComponent, PopularFeaturesComponent,
       HumanResourcesCenterComponent,
       ConfirmationDialogComponent,  PreferenceComponent, DialogTemplateComponent,
       DashAnalyticsBoxComponent, PreviewRecipientsDialogComponent, FilterCriteriaDialogComponent
       
       ],
  imports: [

    //Feature Module
    RaterCommunicationCenterModule,
    HttpClientModule,
    CoreRoutingModule,
    SharedModule,
    MatButtonToggleModule
  ],
  exports: [
    HttpClientModule
    // Feature Module 1,
    // Feature Module 2,
  ],
  providers: [ /** Not Required if using { providedIn: 'root' } on Service */ ],
  entryComponents: [ConfirmationDialogComponent, DialogTemplateComponent, PreviewRecipientsDialogComponent, FilterCriteriaDialogComponent]
})
export class CoreModule { }
