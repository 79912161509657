import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import * as uuid from 'uuid';
import { SpinnerOverlayService} from './spinner-overlay.service'

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT'
}

@Injectable({ providedIn: 'root' })
export class APIService {
  constructor(private amplifyService: AmplifyService,
              private spinner: SpinnerOverlayService,
              ) {
  }
  invokeAPI(apiName: string, path: string, myInit: {}, method: string): Observable<any> {
    let observable: Observable<any>;
     let currentUser = sessionStorage.getItem("currentUser");
     if(currentUser != null  && currentUser.length >0){
      let currentUserJson= JSON.parse(sessionStorage.getItem("currentUser"));
      myInit["headers"] =  {  'Content-Type': 'application/json',
                              'uid':currentUserJson.userId+'' ,
                              'sessionId': currentUserJson.logonHistoryId+'',
                              'requestId': uuid.v4()+''};
     }else {
      myInit["headers"] = { 'Content-Type': 'application/json'};
     }
     this.spinner.show();
    switch (method) {
      case HTTP_METHODS.GET: {
        observable = Observable.fromPromise(this.amplifyService.api().get(apiName, path, myInit));
      }
        break;
      case HTTP_METHODS.POST: {
        observable =  Observable.fromPromise(this.amplifyService.api().post(apiName, path, myInit));
      }
        break;
      case HTTP_METHODS.PUT: {
        observable =  Observable.fromPromise(this.amplifyService.api().put(apiName, path, myInit));
      }
        break;
    }
     observable.subscribe(data => {
      this.spinner.hide();
     }, error => {
      this.spinner.hide();
     },() =>{
      this.spinner.hide();
     });

     return observable;
  }

  async invokeAPINew(apiName: string, path: string, myInit: {}, method: string) {
    let currentUser = sessionStorage.getItem("currentUser");
    if(currentUser != null  && currentUser.length >0){
      let currentUserJson= JSON.parse(sessionStorage.getItem("currentUser"));
      myInit["headers"] =  {  'Content-Type': 'application/json',
                              'uid':currentUserJson.userId+'' ,
                              'sessionId': currentUserJson.logonHistoryId+'',
                              'requestId': uuid.v4()+''};
    }else {
      myInit["headers"] = { 'Content-Type': 'application/json'};
    }
    // this.spinner.show();
    switch (method) {
      case HTTP_METHODS.GET: {
        return await this.amplifyService.api().get(apiName, path, myInit);
      }
        break;
      case HTTP_METHODS.POST: {
        return await this.amplifyService.api().post(apiName, path, myInit);
      }
        break;
      case HTTP_METHODS.PUT: {
        return await this.amplifyService.api().put(apiName, path, myInit);
      }
      break;
    }
     return null;
  }

}
