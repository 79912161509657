import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef  } from '@angular/material';
import { environment } from '../../../../../environments/environment';

export interface DialogData {
  title: string;
  message: string;
  includeOkAndCancel: boolean;
  showLink: boolean;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  // for Preference Alert Box only
  peopleSoftLink;

  constructor(@Inject(MAT_DIALOG_DATA) public data:DialogData,
  public dialogRef: MatDialogRef<ConfirmationDialogComponent>) { }

  ngOnInit() {    
    this.peopleSoftLink = environment.ext.peopleSoftURL;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
