import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '../../services/notification.service';
import { RecipientData, RecipientList } from '../models/recipient-data.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: 'app-recipient-data',
  templateUrl: './recipient-data.component.html',
  styleUrls: ['./recipient-data.component.scss']
})
export class RecipientDataComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public dataSource = new MatTableDataSource<RecipientList>([]);
  notificationId: number;
  recipientData: RecipientData = new RecipientData();

  searchStarted = false;

  private unsubscribeAll: Subject<void> = new Subject();

  displayedColumns: string[];

  constructor(public dialogRef: MatDialogRef<RecipientDataComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private notificationService: NotificationService
  ) {
    this.notificationId = data.notificationId;
    this.recipientData.notificationTypeCode = data.typecode;

    // determine if showing receivedVia
    if (this.recipientData.notificationTypeCode == 'EMAIL') {
      this.displayedColumns = ['employeeId', 'name', 'status'];
    } else {
      this.displayedColumns = ['employeeId', 'name', 'status', 'receivedVia'];
    }
  }

  ngOnInit() {
    this.getFailedRecipients();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getFailedRecipients() {
    this.notificationService.getRecipientData(this.notificationId)
      // add this observable to unsubscribeAll before subscribing
      .pipe(takeUntil(this.unsubscribeAll)).subscribe(
        data => {
          this.recipientData = data;
          this.dataSource.data = data.recipientList;
          this.dataSource.paginator = this.paginator;
        });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    if (filterValue) {
      this.searchStarted = true;
    }

    if (this.searchStarted && !filterValue) {
      this.getFailedRecipients();
      return;
    }

    if (filterValue.length < 2) {
      return;
    }

    this.searchByKeyword(this.notificationId, filterValue);
  }

  searchByKeyword(notificationId: number, filterValue: string) {
    return this.notificationService.getRecipientsByKeyword(notificationId, filterValue)
      .pipe(takeUntil(this.unsubscribeAll)).subscribe(
        data => {
          this.recipientData.recipientList = data;
          this.dataSource.data = data;
          this.dataSource.paginator = this.paginator;
        });
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}