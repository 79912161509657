export interface ExternalLinkItem {
    id: string;
    title: string;
    resourceName: string;
    type: 'item';
    icon?: string;
    url?: string;
    classes?: string;
    desc?: string;
    name?: string;
}

export const externalLinkRoot: ExternalLinkItem[] = [
    {
        id: 'rmn',
        title: 'Rater Management',
        resourceName: 'rmn',
        type: 'item',
        icon: 'i-calendar',
        url: 'http://rm.ets.org',
        classes: 'string',
        desc: 'Manage your availability, view your certification schedules and scoring schedules',
        name: 'My Schedule'
    },
    {
        id: 'one',
        title: 'ONE',
        resourceName: 'one',
        type: 'item',
        icon: 'i-edit',
        url: 'https://one-devintg-corp.etslan.org/CRSAccessWeb/',
        classes: 'string',
        desc: 'Access your scheduled online training, scoring or certification session',
        name: 'Online Scoring'
    },
    {
        id: 'peoplesoft',
        title: 'Peoplesoft',
        resourceName: 'peoplesoft',
        type: 'item',
        icon: 'i-users',
        url: 'https://fedcorphr.ets.org/authn/fedsso.jsp?providerid=velocity',
        classes: 'string',
        desc: 'Update personal and contact information, view paychecks, enroll in direct deposit',
        name: 'Profile & Payroll'
    }
];
