// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  internal: false,
  hmr       : false,
  int : {
    customProvider: 'ETS-IDP',
    hostname: 'placeholder',
    corpUserLoginURL: 'placeholder',
    cognito: {
      region: 'us-east-1',
      identityPoolId: 'placeholder',
      userPoolId: 'placeholder',
      userPoolWebClientId: 'placeholder',
      mandatorySignIn: false,
      storage: window.sessionStorage,
      oauth : {
        domain : 'placeholder',
        redirectUri : 'placeholder' ,
        redirectSignIn: 'placeholder' ,
        redirectSignOut: 'placeholder',
        responseType : 'code'
      }
    }
  },
  ext: {
    customProvider: 'ETS-IDP',
    idpLoginUrl: 'placeholder',
    forgotUsernameURL: 'placeholder',
    forgotPasswordURL: 'placeholder',
    peopleSoftURL: 'placeholder',
    hostname: 'placeholder',
    cognito: {
      region: 'us-east-1',
      identityPoolId: 'placeholder',
      userPoolId: 'placeholder',
      userPoolWebClientId: 'placeholdert',
      mandatorySignIn: false,
      storage: window.sessionStorage,
      oauth : {
        domain : 'placeholder',
        redirectUri: 'placeholder',
        redirectSignIn: 'placeholder',
        redirectSignOut: 'placeholder',
        responseType : 'code'
      }
    }
  },
  api: {
      endpoints: [
          {
             name: 'placeholder',
             endpoint: 'placeholder',
             service: 'execute-api',
             region: 'us-east-1'
         }
     ]
  },
  s3: {
      bucket: 'placeholder', //REQUIRED -  Amazon S3 bucket
      region: 'us-east-1', //OPTIONAL -  Amazon service region
      identityPoolId: 'placeholder',
      customPrefix: {
        public: ''
      }
  }
};
