import { Component, OnInit, Input } from '@angular/core';
import { NavigationItem } from '../../../../core/types/navigation.type';
import { Router } from '@angular/router';
import { UserService } from '../../../../core/services/user.service';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {

  @Input()
  item: NavigationItem;

  profileId: any;

  constructor(private router: Router,
    private userService: UserService) { }

  ngOnInit() {
    this.userService.userDataBS.subscribe(userdata =>{
      this.profileId = userdata[1];
    });
  }

  gotoPage(page){
    if (page === 'home') {
      this.router.navigate(['/core/dashboard']);
    }
    if (page === 'rcc') {
      this.router.navigate(['/core/raterCommCenter']);
    }
    if (page === 'hr') {
      this.router.navigate(['/core/hrCenter']);
    }
  }

}
