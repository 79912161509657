import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatStepper } from '@angular/material';
import { NotificationService } from '../../services/notification.service';
import { CanComponentDeactivate } from "../../../_guards/deactivate.guard";
import { Observable, of, Subject } from "rxjs";
import { RecipientFiltersService } from '../../services/recipient-filters.service';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../components/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, CanComponentDeactivate, OnDestroy {

  public outreachActivityLink = "/raterCommCenter";

  dirty = true;
  notificationHeader: string;

  // Private
  private _unsubscribeAll: Subject<any>;

  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  constructor(private notificationService: NotificationService, 
      private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
      private dialog: MatDialog,
      private recipientFiltersService: RecipientFiltersService,
      private router: Router
    ) {  
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    // 
  }

 
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  isDirty(event) {
    this.dirty = event;
  }

  unSavedData(): Observable<boolean> {
    return of(this.dirty);
  }

  resetData() {
    this.notificationService.currentNotificationBS.next(null);
    this.notificationService.previewRecipientsBS.next(null);
    this.recipientFiltersService.previewRecipientsBS.next(null);
  }
  _confirm() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "Unsaved Data",
        includeOkAndCancel: true,
        message: "Any unsaved data will be lost. Are you sure you want to leave this page? Press 'OK' to continue or 'Cancel' to stay on the current page."
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate([`/core/raterCommCenter`]);
      }
    });
  }  
  navigateTo() {      
    if (this.notificationService.getDirty()) {      
      this._confirm();
    } else {
      this.router.navigate([`/core/raterCommCenter`]);
    }
  }    
}