import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService, HTTP_METHODS } from '../../shared/services/APIService.service';
import { UserContact } from '../../_models/userContact.model';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  apiPath = 'user/';

  constructor(private apiService: APIService) { }

  // endpoints should be a for loop ???
  updatePreference(userId: number, receiveText: boolean, option: string, cellPhone: string): Observable<boolean> {
    // Amplify rest
    let myInit = {
      body: { 'userId': userId, 'option': option, 'receiveText': String(receiveText), 'cellPhone': cellPhone }
    }

    return this.apiService.invokeAPI('repApi', this.apiPath + 'preference', myInit, HTTP_METHODS.POST);
  }

  getUserContactById(userId: number): Observable<UserContact> {
    return this.apiService.invokeAPI('repApi', this.apiPath + userId + '/contact',
      {}, HTTP_METHODS.GET);
  }

  getUserPreferenceById(userId: number) {
    return this.apiService.invokeAPI('repApi', this.apiPath + userId + '/preference',
      {}, HTTP_METHODS.GET);
  }
}