import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.scss']
})
export class DialogTemplateComponent implements OnInit {

  receiveText: boolean;
  success: boolean;
  showMessage: boolean;

  peopleSoftLink;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    this.receiveText = data.receiveText;
    this.success = data.messageSent;
    this.showMessage = data.showMessage;
  }

  ngOnInit() {
    this.peopleSoftLink = environment.ext.peopleSoftURL;
  }

}
