export class DocumentLibraryRow {  
  activeFlag: boolean
  title: string
  description: string
  documentId: number
  fileName: string
  categoryCode: string
  category: string
  status: string
  preSignedUrl: string
  lastUpdateDatetime: string
}
