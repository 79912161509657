import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RecipientFiltersService } from '../../core/services/recipient-filters.service';
import { take, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataResolverService implements Resolve <Observable<any>> {

    constructor(private filterService: RecipientFiltersService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any>{
        // return this.filterService.getCreateFilterForm().pipe(
        //   take(1),
        //   map(userdata => userdata)
        // )
        return this.filterService.getCreateFilterForm();
    }
    
}