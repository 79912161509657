import {Component, OnInit, Input, OnDestroy, EventEmitter, Output} from '@angular/core';
import { NotificationService } from '../../../services/notification.service';
import { SpinnerOverlayService } from '../../../../shared/services/spinner-overlay.service';
import { Type, NOTIFICATION_TYPES, Notification } from '../../../rater-communication-center/models/notification.model';
import { MatStepper } from '@angular/material/stepper';
import { ErrorResonse } from '../../../../shared/types/error.type';
// import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { PermissionsStoreService } from '../../../../_directives/permissions-store.service';
import { NgForm } from '@angular/forms/forms';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-notification-type',
  templateUrl: './notification-type.component.html',
  styleUrls: ['./notification-type.component.scss']
})
export class NotificationTypeComponent  implements OnInit, OnDestroy {
  form = {
    title: '',
    notificationTypeCode: '',
  };  

  @Input() stepper: MatStepper;
  @Output() unsavedData = new EventEmitter<boolean>();
  errorResponse: ErrorResonse;
  private _unsubscribeAll: Subject<any>;
  
  notificationTypes: Type[] = [];
  notificationStatus: Type[] = [
    {code:"DRFT", description:"Draft"},
    {code:"SCHLD", description:"Schedduled"},
    {code:"CNCLD", description:"Canceled"},
    {code:"SENT", description:"Sent"}
  ];

  constructor(private notificationService: NotificationService,
              private permissionsStoreService: PermissionsStoreService,
              private route: ActivatedRoute, 
              private spinner: SpinnerOverlayService) {
    this._unsubscribeAll = new Subject();

  }

  // notificationId: number;
  curNotificationId: number;
  ngOnInit() {
    for(const notificationType of NOTIFICATION_TYPES) {
      if (this.permissionsStoreService.hasPermission([notificationType.permission])) {
        this.notificationTypes.push (notificationType);
      }
    }
    if (!this.notificationTypes.length) {
      return;
    }

    this.notificationService.currentNotificationBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        data => {
          if (data && !data.error) {
            if (data) {
              this.form.title = data.title;          
              this.form.notificationTypeCode = data.type.code;            
              this.curNotificationId = data.id;
            }
          }
          else {
            // initial event
            // console.log('currentNotification BS initial value');
          }  
        }, error => {
          // console.log(error);
        }
      );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  async save(form: NgForm) {
    if(!form.valid) {
      return;
    }
    // only save step 1 part
    this.notificationService.currentNotification = new Notification();
    if (this.curNotificationId) {
      this.notificationService.currentNotification.id = this.curNotificationId;
    }
    this.notificationService.currentNotification.step = 1;
    this.notificationService.currentNotification.title = this.form.title;
    this.notificationService.currentNotification.status = {code:"DRFT", description:"Draft"};    
    this.notificationService.currentNotification.type =  { 
      code: this.form.notificationTypeCode,
      description: NOTIFICATION_TYPES.find(item => item.code === this.form.notificationTypeCode).description
    };

    this.spinner.show();
    const result = await this.notificationService.saveNotification();
    this.spinner.hide();
    if (result) {
      //  save back to currentNotification
      this.notificationService.currentNotification = result;
      this.notificationService.currentNotificationBS.next(result);
      this.stepper.next();
    }
  }
  onChange($event) {
    this.notificationService.setDirty(true);
  }  
}