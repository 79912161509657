import { Component, OnInit } from "@angular/core";
import { interval } from "rxjs";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"]
})
export class LoadingComponent implements OnInit {
  message = "Please wait while the page is loading";
  ellipsis = "";
  interval = 500;
  timeout = 500;
  intervalSubscription;

  constructor() {}

  ngOnInit() {
    this.ellipsis = "";
    this.updateEllipsis();
  }

  ngOnDestroy() {
    setTimeout(()=>{
      if(this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
      }
    }, this.timeout);

  }

  updateEllipsis() {
    this.intervalSubscription = interval(this.interval).subscribe(() => {
      if (this.ellipsis.length < 3) {
        this.ellipsis = this.ellipsis + ".";
      } else {
        this.ellipsis = "";
      }
    });
  }
}
