import { Component, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../shared/services/login.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-ets-idp-login',
  templateUrl: './ets-idp-login.component.html',
  styleUrls: ['./ets-idp-login.component.scss']
})
export class EtsIdpLoginComponent implements OnInit {

  loginForm: FormGroup;

  title = 'Sign In';
  signInButtonText = 'Sign In';
  forgotUsernameURL;
  forgotPasswordURL;
  forgotUsernameText = 'Username';
  forgotPasswordText = 'Password';
  formSubmissionURL ='';



  error: any;
  loading: boolean;



  constructor(
    private router: Router,
    private loginService: LoginService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer) {
     this.loginForm = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });
    this.loginService.loginParams.subscribe(data => {
      this.forgotUsernameURL = this.sanitizer.bypassSecurityTrustUrl(environment.ext.forgotUsernameURL);
      this.forgotPasswordURL = this.sanitizer.bypassSecurityTrustUrl(environment.ext.forgotPasswordURL);
    });
  }

  ngOnInit() {
    this.formSubmissionURL = environment.ext.idpLoginUrl;
    this.loginService.loginErrors.subscribe(data => this.error = data);
  }

  isExternalUser(): boolean {
    return !environment.internal;
  }
  idpLoginUrl(): string {
    return  environment.ext.idpLoginUrl;
  }

  private validateForm(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  public submitForm(e: any) {
    if(this.loginForm.valid){
      let uname = this.loginForm.value.username;
        if (uname == 'hruser' || uname == 'hradmin' || uname == 'etsrater' || uname == 'extrater' || uname == 'corpuser' || uname == 'extadmin') {
          this.router.navigate(['/core', this.loginForm.value.username]);
        } else {
              e.target.submit();
        }
    } else {
      this.validateForm(this.loginForm);
    }
  }

  hasError(): boolean{
    return (this.error  && this.error.length > 0);
  }
}
