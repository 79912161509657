import { Injectable } from "@angular/core";
import {
  APIService,
  HTTP_METHODS
} from "../../shared/services/APIService.service";
import { BehaviorSubject, Observable, from, observable } from "rxjs";
import { RecipientFiltersRow } from "../rater-communication-center/models/recipient-filters.model";
import { PreviewRecipientData } from '../rater-communication-center/models/preview-recipients.model';
import { RecipientFiltersForm } from '../rater-communication-center/models/create-filter.model';
import { Type } from '../rater-communication-center/models/notification.model';

@Injectable({
  providedIn: "root"
})
export class RecipientFiltersService {
  apiPath = 'recipient-filter';
  path = "recipient-filter/list";
  apiPath_existingFilters = "recipient-filter/existingList";
  apiPath_RecipientFilterForm = "recipient-filter/form";  
  apiPath_createFilterPreviewPrecipients = "recipient-filter/previewRecipients";
  data: any;
  previewRecipientsBS: BehaviorSubject<PreviewRecipientData | any>;

  constructor(private apiService: APIService) {
    this.previewRecipientsBS = new BehaviorSubject(null);
  }

  getList(): Observable<any> {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", this.path, {}, HTTP_METHODS.GET)
        .subscribe(
          data => {
            this.data = data;
            subscriber.next(data);
          },
          error => {
            subscriber.error(error.response.data);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }

  getCreateFilterForm(): Observable<RecipientFiltersForm | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", this.apiPath_RecipientFilterForm, {}, HTTP_METHODS.GET)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          error => {
            subscriber.error(error);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  } 

  getExistingFilters(): Observable<RecipientFiltersRow | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", this.apiPath_existingFilters, {}, HTTP_METHODS.GET)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          error => {
            subscriber.error(error);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }

  previewRecipientsForExistingFilter(filterId: number): Observable<PreviewRecipientData | any> | any {    
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath + '/' + filterId + '/' + 'previewRecipients', {}, HTTP_METHODS.GET).
        subscribe(
          data => {
            subscriber.next(data);
            this.previewRecipientsBS.next(data);
          }, error => {
            subscriber.error(error);
          }, () => {
            subscriber.complete();
          });
    });
    return observable;
  }

  previewRecipientsForCreateFilter(filterVO: RecipientFiltersForm): Observable<PreviewRecipientData | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath_createFilterPreviewPrecipients, {body: filterVO}, HTTP_METHODS.POST).
        subscribe(
          data => {
            subscriber.next(data);
            this.previewRecipientsBS.next(data);
          }, error => {
            subscriber.error(error);
          }, () => {
            subscriber.complete();
          });
    });
    return observable;
  }

  getTestsForProgram(programCd: string): Observable<Type | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath + '/' + programCd + '/' + 'tests', {}, HTTP_METHODS.GET).
        subscribe(
          data => {
            subscriber.next(data);            
          }, error => {
            subscriber.error(error);
          }, () => {
            subscriber.complete();
          });
    });
    return observable;
  }

  saveFilter(filterVO: RecipientFiltersForm) : Observable<RecipientFiltersForm | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService.invokeAPI('repApi', this.apiPath, { body: filterVO }, HTTP_METHODS.POST).
        subscribe(
          data => {
            subscriber.next(data);
          }, error => {
            // console.log(error.response);
            subscriber.error(error.response.data);
          });
    });
    return observable;
  }

}
