import { NgModule } from '@angular/core';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationTypeComponent } from './notifications/notification-type/notification-type.component';
import { NotificationContentComponent } from './notifications/notification-content/notification-content.component';
import { NotificationRecipientsComponent } from './notifications/notification-recipients/notification-recipients.component';
import { NotificationScheduleComponent } from './notifications/notification-schedule/notification-schedule.component';
import { NotificationReviewComponent } from './notifications/notification-review/notification-review.component';
import { OutreachActivityTableComponent } from './tables/outreach-activity-table/outreach-activity-table.component';
import { EmailTemplateTableComponent } from './tables/email-template-table/email-template-table.component';
import { RecipientFiltersTableComponent } from './tables/recipient-filters-table/recipient-filters-table.component';
import { DocumentLibraryTableComponent } from './tables/document-library-table/document-library-table.component';
import { RaterCommunicationCenterHomeComponent } from './rater-communication-center-home/rater-communication-center-home.component';
import { SharedModule } from '../../shared/shared.module';
import { RaterCommunicationCenterComponent } from './rater-communication-center.component';
import { RaterCommunicationCenterRoutingModule } from './rater-communication-center-routing.module'
import { NotificationConfirmationComponent } from './notifications/notification-confirmation/notification-confirmation.component';
import { NotificationMessageComponent } from './notifications/notification-content/notification-message/notification-message.component';
import { NotificationAlertComponent } from './notifications/notification-content/notification-alert/notification-alert.component';
import { RecipientDataComponent } from './recipient-data/recipient-data.component';
import { EmailTemplateEditTemplateDialogComponent } from './dialogs/email-template-edit-template-dialog/email-template-edit-template-dialog.component';
import { ViewNotificationComponent } from './view-notification/view-notification.component';
import { ExpireNotificationComponent } from './expire-notification/expire-notification.component';
import { FiltersComponent } from './filters/filters.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { SelectCheckAllComponent } from './filters/select-check-all/select-check-all.component';
import { NotificationImportInfoComponent } from './notifications/notification-recipients/notification-import-info/notification-import-info.component';
import { NotificationApRepComponent } from './notifications/notification-content/notification-ap-rep/notification-ap-rep.component';
import { NotificationScheduleApRepComponent } from './notifications/notification-schedule/notification-schedule-ap-rep/notification-schedule-ap-rep.component';
import { NotificationScheduleAlertComponent } from './notifications/notification-schedule/notification-schedule-alert/notification-schedule-alert.component';

@NgModule(
  {
    declarations: [
      NotificationsComponent,
      NotificationTypeComponent,
      NotificationContentComponent,
      NotificationRecipientsComponent,
      NotificationScheduleComponent,
      NotificationReviewComponent,
      NotificationConfirmationComponent,
      OutreachActivityTableComponent,
      EmailTemplateTableComponent,
      RecipientFiltersTableComponent,
      DocumentLibraryTableComponent,
      RaterCommunicationCenterComponent,
      RaterCommunicationCenterHomeComponent,
      NotificationMessageComponent,
      NotificationAlertComponent,
      NotificationApRepComponent,
      RecipientDataComponent,
      EmailTemplateEditTemplateDialogComponent,
      ViewNotificationComponent,
      ExpireNotificationComponent,
      DocumentUploadComponent,
      FiltersComponent,
      SelectCheckAllComponent,
      NotificationImportInfoComponent,
      NotificationScheduleApRepComponent,
      NotificationScheduleAlertComponent
      ],
    imports :[
      SharedModule,
      RaterCommunicationCenterRoutingModule
    ],
    exports :[],
    providers: [],
    entryComponents: [RecipientDataComponent, EmailTemplateEditTemplateDialogComponent, ViewNotificationComponent, ExpireNotificationComponent, NotificationImportInfoComponent]
  }
)
export class RaterCommunicationCenterModule {}
