import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';
import { popularFeatureRoot, PopularFeatureItem } from '../types/popular.features';

@Injectable({providedIn: 'root'})
export class FeaturesService {

    popularFeatureItemsBS: BehaviorSubject<{}>;
    popularFeatureRoot: PopularFeatureItem[];

    constructor(private userService: UserService){
      this.popularFeatureItemsBS = new BehaviorSubject({});
      this.popularFeatureRoot=[];
      this.userService.userDataBS.subscribe(data => {
        if(data != null){
          this.dataFilter( data);
          this.popularFeatureItemsBS.next(this.popularFeatureRoot);
        }
      });
    };


    dataFilter(apiData: any) {
       popularFeatureRoot.forEach(popular => {
          if(apiData.permissions != null){
            if (apiData.permissions.includes(popular.resourceName)) {
              this.popularFeatureRoot.push(popular);
            }
          }
        })
     }
}
