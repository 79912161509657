import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Type } from '../models/notification.model';
import { RecipientFiltersForm } from '../models/create-filter.model';
import { RecipientFiltersService } from '../../services/recipient-filters.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatCheckboxChange } from '@angular/material';
import { ConfirmationDialogComponent } from '../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { PreviewRecipientsDialogComponent } from '../../components/shared/preview-recipients-dialog/preview-recipients-dialog.component';
import { ToggleSelectionService } from './toggle-selection.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  createFilterFormGroup: FormGroup;
  isDataAvailable:boolean = false;
  programList: Type[];
  testsList: Type[]
  levelOfExpList: Type[]
  companyCodeList: Type[]
  stateProvinceList: Type[]
  roleTypeList: Type[]
  workingTitleList: Type[]
  responseCodeList: Type[]
  recipientFilterFormData: RecipientFiltersForm; 
  isDisabled:boolean = false;
  activeCount: number = 0;
  totalCount: number = 0;  
  //displayCountsMessage: boolean = false;
  saveMessage: any;
  filterNameExistsError: any;
  filterVO: any;  
  // Private
  private _unsubscribeAll: Subject<any>;  

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private recipientFiltersService: RecipientFiltersService,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    public toggleSelectionService: ToggleSelectionService) { 
    this.recipientFilterFormData = this.route.snapshot.data.recipientFilterFormData; //get data from resolver     
    this.programList = this.recipientFilterFormData.programList;
    this.levelOfExpList = this.recipientFilterFormData.levelOfExpList;
    this.companyCodeList = this.recipientFilterFormData.companyCodeList;    
    this.stateProvinceList = this.recipientFilterFormData.stateProvinceList;
    this.roleTypeList = this.recipientFilterFormData.roleTypeList;
    this.workingTitleList = this.recipientFilterFormData.workingTitleList;
    this.responseCodeList = this.recipientFilterFormData.responseCodeList;
    this.createFilterFormGroup =  this.fb.group({
      filterName: [null, Validators.required],
      programs: [null, Validators.required],
      tests: [this.testsList],
      experienceLevel: [this.levelOfExpList],
      companyCode: [this.companyCodeList],
      highestRole: [this.roleTypeList],
      state: [this.stateProvinceList],
      workingTitle: [this.workingTitleList],
      responseCodes: [this.responseCodeList],
      filterId: [0]
    })
    this.toggleSelectionService.displayCountsMessageForFilter = false;
    this.saveMessage = null;
    this.recipientFiltersService.previewRecipientsBS.next(null);
  }

  ngOnInit() {      
    this.recipientFiltersService.previewRecipientsBS      
      .subscribe(
        data => {
          if(data) {
            this.activeCount = data.activeCount;
            this.totalCount = data.totalCount;            
          }
        }, error =>{
          // console.log(error);
        });  
  }  

  saveFilter() {
    this.saveMessage = null;
    if (this.createFilterFormGroup.valid) {
      this.filterVO = this.buildFilterVO();      
      this.recipientFiltersService.saveFilter(this.filterVO).subscribe(data => {
        if (data) {
          this.saveMessage = "Filter has been saved."  
          this.createFilterFormGroup.patchValue({filterId: data.filterId});
        }                    
      }, error => {
        if (error.errors) {
          for (let entry of error.errors) {
            let data = (<string>entry).split(':');            
            if (data[0].includes("filterName")) {
              this.createFilterFormGroup.get('filterName').setErrors({'filterNameExists':true});   
              this.filterNameExistsError = data[1]; 
            }
          }
        }   
      });
    } else {      
      this.validateAllFormFields(this.createFilterFormGroup)
   }
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private buildFilterVO(): any {
    let criteria = null;
    var pgms: Type[] = [];
    let temp: Type[] = [{ code: "ALL", description: "ALL" }]
    pgms.push(this.createFilterFormGroup.value.programs);
    criteria = Object.assign({}, this.createFilterFormGroup.value);    
    //build criteria
    criteria.programs = pgms;
    
    if (this.createFilterFormGroup.value.tests == null) {
       criteria.tests = new Array<Type>();
    } else if (this.createFilterFormGroup.value.tests && this.testsList.length
      && this.createFilterFormGroup.value.tests.length === this.testsList.length) {
        criteria.tests = temp;
    }
    if (this.createFilterFormGroup.value.experienceLevel && this.levelOfExpList.length
      && this.createFilterFormGroup.value.experienceLevel.length === this.levelOfExpList.length) {
        criteria.experienceLevel = temp;
    }
    if (this.createFilterFormGroup.value.companyCode && this.companyCodeList.length
      && this.createFilterFormGroup.value.companyCode.length === this.companyCodeList.length) {
        criteria.companyCode = temp;
    }
    if (this.createFilterFormGroup.value.highestRole && this.roleTypeList.length
      && this.createFilterFormGroup.value.highestRole.length === this.roleTypeList.length) {
        criteria.highestRole = temp;
    }
    if (this.createFilterFormGroup.value.state && this.stateProvinceList.length
      && this.createFilterFormGroup.value.state.length === this.stateProvinceList.length) {
        criteria.state = temp;
    }
    if (this.createFilterFormGroup.value.workingTitle && this.workingTitleList.length
      && this.createFilterFormGroup.value.workingTitle.length === this.workingTitleList.length) {
        criteria.workingTitle = temp;
    }
    if (this.createFilterFormGroup.value.responseCodes && this.responseCodeList.length
      && this.createFilterFormGroup.value.responseCodes.length === this.responseCodeList.length) {
        criteria.responseCodes = temp;
    }
    // setting working title and rspcode to null if not AP.
    if (this.createFilterFormGroup.value.programs.description != 'AP') {
      criteria.workingTitle =  new Array<Type>();
      criteria.responseCodes = new Array<Type>();
    } 
    return criteria;
  }

  onChangeEvent(event): void { 
    this.toggleSelectionService.displayCountsMessageForFilter = false;
    if (this.createFilterFormGroup.value.programs.description) {
      this.createFilterFormGroup.value.tests = null;
      this.recipientFiltersService.getTestsForProgram(this.createFilterFormGroup.value.programs.description).subscribe(data => {        
        this.testsList = data;
      }, error => {
        // console.log("Error getting tests for given program.", error);        
      });
    }
    if (this.createFilterFormGroup.value.programs.description != 'AP') {      
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }    
  }

  onChangeCriteria(event): void {    
    this.toggleSelectionService.displayCountsMessageForFilter = false;
  }

  onBack() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "Unsaved Data",
        includeOkAndCancel: true,
        message: "Any unsaved data will be lost. Are you sure you want to leave this page? Press 'OK' to continue or 'Cancel' to stay on the current page."
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/core/raterCommCenter']); 
      }
    });
  }

  previewRecipients() {
    this.filterVO = this.buildFilterVO();     
    this.dialog.open(PreviewRecipientsDialogComponent, {
      disableClose: true,
      data: {        
        filterVO: this.filterVO
      }
    });
    this.toggleSelectionService.displayCountsMessageForFilter = true;
  }

  ngOnDestroy(): void {
    if(this._unsubscribeAll){
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
    }
  }
}
