import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RaterCommunicationCenterHomeComponent } from './rater-communication-center-home/rater-communication-center-home.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationConfirmationComponent } from './notifications/notification-confirmation/notification-confirmation.component';
import { RaterCommunicationCenterComponent } from './rater-communication-center.component';
import { FiltersComponent } from './filters/filters.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { DataResolverService } from '../../shared/services/data-resolver.service';
import { UploadDataResolverService } from '../../shared/services/upload-data-resolver.service';

const appRaterCommunicationCenterRoutes: Routes = [
  {path:'raterCommCenter', component:RaterCommunicationCenterComponent,
    children : [
      {path: 'home', component: RaterCommunicationCenterHomeComponent},
      {path: 'notifications/confirmation', component: NotificationConfirmationComponent },
      {path: 'notifications', component: NotificationsComponent},
      {path: 'filters', component: FiltersComponent, resolve: {recipientFilterFormData: DataResolverService}, pathMatch: 'full'},
      {path: 'document-upload', component: DocumentUploadComponent, resolve: {documentUploadFormData: UploadDataResolverService}, pathMatch: 'full' },
      {path: '', redirectTo:"home", pathMatch:'full'}
    ]},
  {path: '', redirectTo:'raterCommCenter', pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forChild(appRaterCommunicationCenterRoutes)],
  exports: [ RouterModule]
})
export class RaterCommunicationCenterRoutingModule {

}
