import { Injectable } from "@angular/core";
import { APIService, HTTP_METHODS } from "../../shared/services/APIService.service";
import { BehaviorSubject, Observable, from, observable } from "rxjs";
import { HttpClient, HttpBackend, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http'
import { DocumentUploadFormData } from '../rater-communication-center/models/document-upload-data.model';
import { DocumentLibraryRow } from '../rater-communication-center/models/document-library.model';

@Injectable({
  providedIn: "root"
})
export class DocumentUploadService {
  apiPath ='document-library';
  apiPath_UploadFormData ='document-library/upload/form-data';
  apiPath_UploadPreSignedURL ='document-library/upload/pre-signed-url/';
  apiPath_TitleDuplicateCheck ='document-library/title-duplicate-check';
  data: any;
  constructor(private apiService: APIService,
    private http: HttpClient,
    private handler: HttpBackend) {
  }

  getDocumentUploadFormData(): Observable<DocumentUploadFormData | any> | any {
    let observable = new Observable(subscriber => {
      this.apiService
        .invokeAPI("repApi", this.apiPath_UploadFormData, {}, HTTP_METHODS.GET)
        .subscribe(
          data => {
            subscriber.next(data);
          },
          error => {
            subscriber.error(error);
          },
          () => {
            subscriber.complete();
          }
        );
    });
    return observable;
  }

  getUploadPreSignedUrl(categoryCode : string, fileName : string): Observable<any> {
    let uri = this.apiPath_UploadPreSignedURL + categoryCode + '/' + fileName;
   return this.apiService.invokeAPI("repApi", uri, {}, HTTP_METHODS.GET);
  }

  titleDuplicateCheck(title : string): Observable<any> {
    let uri : string = this.apiPath_TitleDuplicateCheck + '?title=' + title;
    return this.apiService.invokeAPI("repApi", uri, {}, HTTP_METHODS.GET);
  }

  uploadFileToS3(preSignedUrl: string, file: File): Observable<any>  {
    this.http = new HttpClient(this.handler); // to reset the header
    const headers = new HttpHeaders({
      'Accept': '*/*',
      'Content-Type': file.type
       });
    const req = new HttpRequest('PUT', preSignedUrl, file, { headers: headers, reportProgress: true });
    return this.http.request(req);
  }

  saveRecord(documentLibraryRecord : DocumentLibraryRow): Observable<any> {
    return  this.apiService.invokeAPI("repApi", this.apiPath, { body: documentLibraryRecord }, HTTP_METHODS.POST);
  }
}
