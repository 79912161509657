export class NotificationDetail {
    id: number;
    name: string;
    type: string;
    typeCode: string;
    communicationMethod: string;
    communicationMethodCode: string;
    status: string;
    statusCode: string;
    createdBy: string;
    createdDateTime: string;
    lastModifiedBy: string;
    lastModifiedDateTime: string;
    fileName: string;
    filterName: string;
    scheduleDate: string;
    content: string;
    sender: string;
    templateName: string;
    templateDescription: string;
    templateContent: string;
}