import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-spinner-dialog',
  templateUrl: './api-spinner-dialog.component.html',
  styleUrls: ['./api-spinner-dialog.component.scss']
})
export class ApiSpinnerDialogComponent implements OnInit {


  constructor() { }

  ngOnInit() {
  }

}
