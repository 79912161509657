import { Directive } from '@angular/core';
import {AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";

export function ValidateCorporateEmailDirective(emailFormat: RegExp): ValidatorFn{

  return (control: AbstractControl): {[key: string]: any} | null => {
    let isValid = true;
    let emails:string = control.value;
    let invalidEmails=[];
    if(emails){
       emails.split(',').forEach( email => {
        email = email.trim();
         let validationErrors: ValidationErrors = Validators.email(new FormControl(email));
         if(validationErrors != null || !emailFormat.test(email)){
           invalidEmails.push(email);
         }
      });
      isValid = (invalidEmails.length == 0);
    }
    return isValid ? null : {'invalidCorporateEmails': {value: invalidEmails.toString()}};
  };
}
