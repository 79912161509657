import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import {
  MatInput,
  MatTableDataSource,
  MatDialogRef,
  MatDialog
} from "@angular/material";
import { MatSort } from "@angular/material/sort";
import { FormGroup, FormControl } from "@angular/forms";
import { EmailTemplateService } from "../../../services/email-template.service";
import { EmailTemplateRow } from "../../models/email-template.model";
import { EmailTemplateEditTemplateDialogComponent } from "../../dialogs/email-template-edit-template-dialog/email-template-edit-template-dialog.component";
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: "app-email-template-table",
  templateUrl: "./email-template-table.component.html",
  styleUrls: ["./email-template-table.component.scss"]
})
export class EmailTemplateTableComponent implements OnInit, OnDestroy {
  @Input() displayedColumns: any[] = [
    "active",
    "name",
    "processes",
    "programs",
    "departments",
    "lastModifiedDateTime"
  ];
  public keyword: string;
  public isLoading = true;
  public dataSource = new MatTableDataSource<EmailTemplateRow>([]);
  //filterBlankSecret is used to reset the filter, filter accepts a string, and empty string is falsey, used to refilter.
  //There is likely a better way, need to refactor
  private filterBlankSecret =
    "~!_blank_!~" + Math.floor(Math.random() * 999999999) + "_=blank=_";

  @ViewChild("keywordInput", { read: MatInput, static: false })
  keywordInput: MatInput;
  @ViewChild("fromInput", { read: MatInput, static: false })
  fromInput: MatInput;
  @ViewChild("toInput", { read: MatInput, static: false }) toInput: MatInput;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  message: any;
  syncStatus: any;

  private unsubscribeAll: Subject<void> = new Subject();

  public filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    keyword: new FormControl()
  });

  constructor(
    private emailTemplateService: EmailTemplateService,
    private dialogRef: MatDialogRef<EmailTemplateEditTemplateDialogComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.getEmailTemplate();
    this.initFilterPredicate();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // sort case-insensitive
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] != null ? data[sortHeaderId].toLowerCase() : data[sortHeaderId];
  }

  getEmailTemplate() {
    this.emailTemplateService.getList().pipe(takeUntil(this.unsubscribeAll)).subscribe(
      data => {
        this.isLoading = false;
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
      },
      error => {
        this.isLoading = false;
      }
    );
  }

  sortData(e) {
    // 
  }

  applyFilter(filterValue: string) {
    this.dataSource.data = this.dataSource.data;
    if (filterValue) {
      this.keyword = filterValue;
      filterValue = filterValue.trim().toLowerCase();
    } else {
      filterValue = this.filterBlankSecret;
    }

    this.dataSource.filter = filterValue;
  }

  openDialog(templateId: number) {
    this.message = null;
    this.emailTemplateService.returnMessage.subscribe(data => {
      this.message = data;
    })
    this.dialogRef = this.dialog.open(
      EmailTemplateEditTemplateDialogComponent,
      {
        width: "80%",
        height: "80vh",
        panelClass: "email-Template-edit-template-dialog",
        disableClose: true,
        data: {
          templateId: templateId
        }
      }
    );
    this.dialogRef.afterClosed().pipe(takeUntil(this.unsubscribeAll)).subscribe((data)=>{
      // only data isTrue, call DB again
      if(data){
        // this timeout is just for the issue of spinner service.
        // let the first request finish first
        //setTimeout(()=> this.getEmailTemplate(), 2000);
        this.getEmailTemplate();
      }
    });
  }

  clearFilters() {
    this.keyword = null;
    this.dataSource.filter = null;
    this.filterForm.reset();
  }

  clearDate(input) {
    let filterValue = this.filterBlankSecret;

    if (input == "to") {
      this.toInput.value = "";
    }

    if (input == "from") {
      this.fromInput.value = "";
    }

    if (this.keywordInput.value.length) {
      filterValue = this.keywordInput.value;
    }

    this.applyFilter(filterValue);
  }

  initFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter: string) => {
      const keys = ['name', 'description', 'processes', 'programs', 'departments', 'createdBy', 'lastModifiedBy'];
      let keywordFound = false;
      let dateFound = false;

      const fromDate = this.fromInput.value
        ? new Date(this.fromInput.value)
        : false;
      const toDate = this.toInput.value ? new Date(this.toInput.value) : false;
      const hasModifiedDate = data && data.lastModifiedDateTime ? true : false;

      if (filter == this.filterBlankSecret) {
        filter = "";
      }

      if (filter) {
        for (const key of keys) {
          keywordFound = data[key]
            ? data[key]
                .toString()
                .trim()
                .toLowerCase()
                .indexOf(filter.toLowerCase()) !== -1
            : false;
          if (keywordFound) {
            break;
          }
        }
      }

      if (hasModifiedDate) {
        let modifiedDate = new Date(data.lastModifiedDateTime);
        modifiedDate.setHours(0, 0, 0, 0);
        if (fromDate && toDate) {
          dateFound = modifiedDate >= fromDate && modifiedDate <= toDate;
        } else if (fromDate) {
          dateFound = modifiedDate >= fromDate;
        } else if (toDate) {
          dateFound = modifiedDate <= toDate;
        }
      }

      if (filter && (fromDate || toDate)) {
        return keywordFound && dateFound;
      } else if (filter) {
        return keywordFound;
      } else if (fromDate || toDate) {
        return dateFound;
      } else {
        return true;
      }
    };
  }

  emailTemplateSync() {
    this.emailTemplateService.sync().pipe(takeUntil(this.unsubscribeAll)).subscribe(
      data => {
        this.dataSource.data = data.templateDetails;
        this.dataSource.paginator = this.paginator;
        this.message = data.message;
        this.syncStatus = data.status;
      },
      error => {
      }
    );
  }
  
  onStatusChange($event: MatSlideToggleChange, templateId: number, element: HTMLInputElement) {
    this.message = null;
    if($event.checked){
      this.emailTemplateService.activate(templateId).pipe(takeUntil(this.unsubscribeAll))
      .subscribe(data => {
        if(data.status == 'error'){
          element.checked = false;
          this.message = data.message;
          window.scroll(0,0);
        }
      });
    }
    else{
      this.emailTemplateService.deactivate(templateId).pipe(takeUntil(this.unsubscribeAll))
      .subscribe(data => {
        if(data.status == 'error'){
          element.checked = true;
          this.message = data.message;
          window.scroll(0,0);
        }
        else{
          this.message = data.message;
          window.scroll(0,0);
        }
      });
    }
  }

  ngOnDestroy(): void {
    // for clearing current 'save success' message
    this.emailTemplateService.returnMessage.next("");
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}