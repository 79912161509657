import { Component,OnInit } from "@angular/core";
import { LoginService } from "../../shared/services/login.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"]
})
export class SignInComponent implements OnInit {
  public landingContent = {
    title: "Welcome ETS<br/>Rater/Reader Community",
    byline:
      '<strong>Don\'t have an account?</strong> Find <a href="http://www.ets.org/scoring_opportunities" target="_blank">Scoring Opportunities&#xfeff;<span class="ext-icon">&#xfeff;<i class="fa fa-external-link-square"></i></span></a> at ETS.',
    sections: [
      {
        label: "Important Links",
        content: "",
        links: [
          {
            label: "Contact a Scoring Program",
            href: "http://www.ets.org/raters/contacts"
          },
          {
            label: "Finding Scoring Opportunities",
            href: "http://www.ets.org/scoring_opportunities"
          },
          {
            label: "Access your Unsubmitted Job Applications",
            href:
              "https://fed.ets.org/authn/fedsso.jsp?providerid=pereless&appid=pereless&returnurl=https://etscrs.submit4jobs.com/index.cfm?fuseaction=85332.gologin"
          }
        ]
      },
      {
        label: "Technical Support",
        content:
          "If you need technical assistance with Portal sign in, RM Availability & Schedules or ONE systems: <strong>Contact 1-877-728-4201</strong>.<br/><br/>Please note that the supported Internet browsers for these systems are:<ul><li>For PC users — Mozilla Firefox or Google Chrome.</li><li>For Mac users — Apple Safari.</li>",
        links: []
      }
    ]
  };

  title = "Sign In";
  corpUserLoginURL;
  internal: boolean = false;

  constructor(
    private loginService: LoginService,
    private sanitizer: DomSanitizer
  ) {
    this.loginService.loginParams.subscribe(data => {
      if (data) {
        this.corpUserLoginURL = sanitizer.bypassSecurityTrustUrl(
          data.int.corpUserLoginURL
        );
        //this.corpUserLoginURL = data.int.corpUserLoginURL;
        this.internal = data.internal;
      }
    });
  }

  public ngOnInit() {  }

}
