import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CoreComponent } from './core.component';
import { RaterCommunicationCenterComponent } from './rater-communication-center/rater-communication-center.component';
import { HumanResourcesCenterComponent } from './components/human-resources-center/human-resources-center.component';
import { PreferenceComponent } from './components/preference/preference.component';
import { RaterCommunicationCenterModule } from './rater-communication-center/rater-communication-center.module';
import { RaterCommunicationCenterHomeComponent } from './rater-communication-center/rater-communication-center-home/rater-communication-center-home.component';
import { ErrorComponent } from './components/error/error.component';

const appCoreRoutes: Routes = [

  {path: '', component:CoreComponent,
      children: [
        {path: 'dashboard', component: DashboardComponent},
        {path: 'raterCommCenter', component: RaterCommunicationCenterComponent},
        {path: 'hrCenter', component: HumanResourcesCenterComponent},
        {path: 'preference', component: PreferenceComponent},
        {path: 'error', component: ErrorComponent}
      ]}
]

@NgModule({
    imports: [RouterModule.forChild(appCoreRoutes)],
  exports : [RouterModule]
})
export class CoreRoutingModule {}

