import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService, HTTP_METHODS } from '../../shared/services/APIService.service';

@Injectable({
    providedIn: 'root'
})

export class RaterCommunicationService {

    selectedTab: string
    constructor() {
    }

    setSelectedTab(val: string) {
        this.selectedTab = val;
    }
}