import { Component, OnInit, OnDestroy, ElementRef, Inject, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators
} from "@angular/forms";
import { EmailTemplateService } from "../../../services/email-template.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { MatDialogRef, MAT_DIALOG_DATA, MatOptgroup, MatOption } from '@angular/material';
import { TemplateSubstitution, TemplateMergeData, Process, Program, Department, Substitution } from '../../models/email-template-detail.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermissionsStoreService } from "../../../../_directives/permissions-store.service";

@Component({
  selector: "app-email-template-edit-template-dialog",
  templateUrl: "./email-template-edit-template-dialog.component.html",
  styleUrls: ["./email-template-edit-template-dialog.component.scss"]
})
export class EmailTemplateEditTemplateDialogComponent implements OnInit, OnDestroy {
  dialogForm: FormGroup;
  content: SafeHtml;
  substitutions: Array<Substitution>;
  templateSubstitutionList: Array<TemplateSubstitution>;
  templateMergeDataList: Array<TemplateMergeData>;
  processList: Array<Process>;
  programList: Array<Program>;
  departmentList: Array<Department>;
  selectedMergeFields = [];
  selectedFreeFormFields = [];
  enterMergeFieldError = false;

  returnBody = {};

  templateId: number;
  subject: string; // Template Abridgement, should be shown on UI
  metaData = [];

  selectPermission = this.permissionService.hasPermission(['template.action']);
  message: string;

  active: boolean;

  clickGenerate: boolean = false;

  private unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<EmailTemplateEditTemplateDialogComponent>,
    private api: EmailTemplateService,
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private permissionService: PermissionsStoreService
  ) {
    this.templateId = data.templateId;
  }

  ngOnInit() {
    this.buildForm();
    this.content = "";
    this.substitutions = [];
    this.templateSubstitutionList = [];
    this.templateMergeDataList = [];
    this.processList = [];
    this.programList = [];
    this.departmentList = [];
    this.selectedMergeFields = [];
    this.fetchData();
  }

  buildForm() {
    this.dialogForm = this.fb.group({
      templateName: [{ value: "", disabled: true }, Validators.required],
      subject: ["", Validators.required],

      process: [""],
      program: [""],
      department: [""],
    });
  }

  fetchData() {
    if (this.templateId) {
      this.api.getContentByID(this.templateId).pipe(takeUntil(this.unsubscribeAll)).subscribe(
        data => {
          // I suggest not do this, copying a large amount of data may waste some time 
          this.initData(data);
        },
        error => {
          // console.log(error);
        }
      );
    }
  }

  initData(data) {
    this.active = data.activeFlag;
    this.dialogForm.controls["templateName"].setValue(data.templateName);
    this.dialogForm.controls["subject"].setValue(data.subject);

    this.returnBody["templateId"] = data.templateId;
    this.returnBody["metaData"] = data.metaData;

    if (data.content) {
      this.content = this.sanitizer.bypassSecurityTrustHtml(data.content);
    } else {
      this.message = "Could not retrieve content data.";
    }

    this.substitutions = data.substitutions;

    this.templateSubstitutionList = data.templateSubstitutionList;
    this.templateMergeDataList = data.templateMergeDataList;
    this.processList = data.processList;
    this.programList = data.programList;
    this.departmentList = data.departmentList;
    this.metaData = data.metaData;
    // Proccess Program Department
    this.constructPPD();
    this.constructSubstitutionForm(this.substitutions);
    if (this.active) {
      // lock the form
      this.dialogForm.disable();
      // for 'substitutions' and 'mergeFields', set in their FormControl constructor  
    }
  }

  constructSubstitutionForm(substitutions) {
    let group = {};
    substitutions.forEach(sub => {
      group[sub.substitutionName] = new FormControl({ value: sub.substitutionTypeCode, disabled: (!this.selectPermission || this.active) });
    });
    this.dialogForm.removeControl("substitutions");
    this.dialogForm.addControl("substitutions", this.fb.group(group));
    // show previously change
    this.generateFields(false);
  }

  // Proccess Program Department
  constructPPD() {
    for (let meta of this.metaData) {
      if (meta.templateMetaDataCategoryType == "PRCSS") {
        this.dialogForm.controls["process"].setValue(meta.templateMetaDataType);
      }
      if (meta.templateMetaDataCategoryType == "DPRMT") {
        this.dialogForm.controls["department"].setValue(meta.templateMetaDataType);
      }
      if (meta.templateMetaDataCategoryType == "PRGRM") {
        this.dialogForm.controls["program"].setValue(meta.templateMetaDataType);
      }
    }
  }

  // if byUser, change the value of 'clickGenerate' and showAlertMessage
  generateFields(byUser: boolean) {
    if (byUser) {
      this.clickGenerate = true;
    }
    // {Phone: MERGE, ....}
    const values = this.dialogForm.controls["substitutions"].value;
    const keys = Object.keys(values);
    let group = {};

    if (keys.length) {
      this.generateSelectedFields(values, keys, group);

      this.dialogForm.removeControl("mergeFields");
      if (this.selectedMergeFields.length > 0) {
        this.enterMergeFieldError = false;
        this.dialogForm.addControl("mergeFields", this.fb.group(group));
        /*
        setTimeout(() => {
          let el = this.el.nativeElement.querySelector("#merge-fields");
          this.scrollTo(el);
        }, 0);
        */
      } else if (byUser) {
        this.enterMergeFieldError = true;
        setTimeout(() => {
          this.enterMergeFieldError = false;
        }, 5000);
      }
    }
  }

  generateSelectedFields(values, keys, group) {
    // fname, lname
    let availableMergeFields = [];
    // Phone, Program, Email
    this.selectedMergeFields = [];
    this.selectedFreeFormFields = [];

    this.templateMergeDataList.forEach(item => {
      availableMergeFields.push(item.code);
    });

    for (let i = 0; i < keys.length; i++) {
      if (values[keys[i]] == "MERGE") {
        let value = availableMergeFields.includes(keys[i]) ? keys[i] : null;
        let valueFromDB = this.substitutions[i].mergeDataCode;
        group[keys[i]] = this.fb.control({ value: (valueFromDB ? valueFromDB : value), disabled: (!this.selectPermission || this.active) });
        this.selectedMergeFields.push(keys[i]);
      }
      else if (values[keys[i]] == "FFORM") {
        this.selectedFreeFormFields.push(keys[i]);
      }
    }
  }

  saveChanges() {
    this.dialogForm.markAllAsTouched();
    if (this.dialogForm.valid) {
      this.returnBody["templateName"] = this.dialogForm.get('templateName').value;
      this.returnBody["subject"] = this.dialogForm.get('subject').value;

      // if user doesn't click generate fileds, we help add selectedFields to responseBody
      if (!this.clickGenerate) {
        const values = this.dialogForm.controls["substitutions"].value;
        const keys = Object.keys(values);
        this.selectedFreeFormFields = [];
        this.selectedMergeFields = [];
        for (let i = 0; i < keys.length; i++) {
          if (values[keys[i]] == "MERGE"){
            this.selectedMergeFields.push(keys[i]);
          }
          if (values[keys[i]] == "FFORM") {
            this.selectedFreeFormFields.push(keys[i]);
          }
        }
      }

      let subReturn = [];
      // combine 'substitutions' and 'mergeFields' to a list of Substitution
      // [{substitutionName: "", substitutionValue: ""}, ...]
      // refer to selectedMergeFields
      let keys = this.selectedMergeFields;
      if (this.dialogForm.get('mergeFields')) {
        let valueMap = this.dialogForm.get('mergeFields').value;
        for (var i = 0; i < keys.length; i++) {
          // only push merged value
          subReturn.push(new Substitution(this.getIdByKey(keys[i]), keys[i], 'MERGE', valueMap[keys[i]], this.templateId));
        }
      }
      else{
        for (let merge of this.selectedMergeFields) {
          subReturn.push(new Substitution(this.getIdByKey(merge), merge, 'MERGE', '', this.templateId));
        }
      }

      for (let fform of this.selectedFreeFormFields) {
        subReturn.push(new Substitution(this.getIdByKey(fform), fform, 'FFORM', '', this.templateId));
      }

      this.returnBody["substitutions"] = subReturn;

      for (let meta of this.metaData) {
        if (meta.templateMetaDataCategoryType == "PRCSS") {
          meta.templateMetaDataType = this.dialogForm.get('process').value;
        }
        if (meta.templateMetaDataCategoryType == "DPRMT") {
          meta.templateMetaDataType = this.dialogForm.get('department').value;
        }
        if (meta.templateMetaDataCategoryType == "PRGRM") {
          meta.templateMetaDataType = this.dialogForm.get('program').value;
        }
      }

      this.api.currentTemplate = Object.assign({}, this.returnBody);

      this.api.saveTemplate().pipe(takeUntil(this.unsubscribeAll)).subscribe(
        data => {
          this.closeDialog(true);
        },
        error => {
          // console.log(error);
          this.closeDialog(true);
        }
      );
    } else {
      this.scrollToError();
    }
  }

  getIdByKey(key: string): number {
    for (let sub of this.substitutions) {
      if (sub.substitutionName == key) {
        return sub.id;
      }
    }
  }

  isMergeFieldAvailable(value: string) {
    if(!this.dialogForm.controls["mergeFields"]){
      return true;
    }
    const values = this.dialogForm.controls["mergeFields"].value;
    const keys = Object.keys(values);
    let isAvailable = true;
    if (keys.length) {
      keys.forEach(key => {
        if (values[key] == value) {
          isAvailable = false;
        }
      });
    }
    return isAvailable;
  }

  generateFieldsDisabled() { }

  closeDialog(refresh: boolean) {
    this.dialogRef.close(refresh);
  }

  hasError(controlName: string, errorName: string) {
    return this.dialogForm.controls[controlName].hasError(errorName);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  scrollToError(): void {
    const firstElementWithError = this.el.nativeElement.querySelector(".ng-invalid[formControlName]");
    if (firstElementWithError) {
      this.scrollTo(firstElementWithError);
      firstElementWithError.focus();
    }
  }

  // for program list only
  private selectAll = false;

  toggleAllSelection(event) {
    if (this.selectAll) {
      this.dialogForm.controls["program"].setValue([]);
      this.selectAll = false;
      return;
    }
    if (event.value.includes('all')) {
      let programCodes = Array.from(this.programList, e => e.code);
      this.dialogForm.controls["program"].setValue(programCodes);
      this.selectAll = true;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}