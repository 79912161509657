import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatInput, MatTableDataSource, MatDialog } from "@angular/material";
import { MatSort } from '@angular/material/sort';
import { FormGroup, FormControl } from "@angular/forms";
import { DocumentLibraryService } from '../../../services/document-library.service';
import { DocumentLibraryRow } from '../../models/document-library.model';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-document-library-table',
  templateUrl: './document-library-table.component.html',
  styleUrls: ['./document-library-table.component.scss']
})
export class DocumentLibraryTableComponent implements OnInit, OnDestroy {
  @Input() displayedColumns: any[] = [
    "activeFlag",
    "title",
    "category",
    "fileName",
    "description",
    "lastUpdateDatetime"
  ];
  public keyword: string;
  public isLoading = true;
  public dataSource = new MatTableDataSource<DocumentLibraryRow>([]);
  private filterBlankSecret = "~!_blank_!~" + Math.floor(Math.random() * 999999999) + "_=blank=_";

  @ViewChild("keywordInput", { read: MatInput, static: false })
  keywordInput: MatInput;
  @ViewChild("fromInput", { read: MatInput, static: false })
  fromInput: MatInput;
  @ViewChild("toInput", { read: MatInput, static: false }) toInput: MatInput;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  message: any;
  syncStatus: any;

  private unsubscribeAll: Subject<void> = new Subject();

  public filterForm = new FormGroup({
    fromDate: new FormControl(),
    toDate: new FormControl(),
    keyword: new FormControl()
  });

  constructor(
    private documentLibraryService: DocumentLibraryService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.loadDocumentLibrary();
    this.initFilterPredicate();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] != null ? data[sortHeaderId].toString().toLowerCase() : data[sortHeaderId];
  }

  loadDocumentLibrary() {
    this.documentLibraryService.getList().pipe(takeUntil(this.unsubscribeAll)).subscribe(
      data => {
        this.isLoading = false;
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
      },
      error => {
        this.isLoading = false;
      }
    );
  }

  sortData(e) {
    //
  }

  applyFilter(filterValue: string) {
    if (filterValue) {
      this.keyword = filterValue;
      filterValue = filterValue.trim().toLowerCase();
    } else {
      filterValue = this.filterBlankSecret;
    }
    this.dataSource.filter = filterValue;
  }

  clearFilters() {
    this.keyword = null;
    this.dataSource.filter = null;
    this.filterForm.reset();
  }

  clearDate(input) {
    let filterValue = this.filterBlankSecret;

    if (input == "to") {
      this.toInput.value = "";
    }

    if (input == "from") {
      this.fromInput.value = "";
    }

    if (this.keywordInput.value.length) {
      filterValue = this.keywordInput.value;
    }

    this.applyFilter(filterValue);
  }

  initFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter: string) => {
      const keys = ["title","category", "description"];
      let keywordFound = false;
      let dateFound = false;

      const fromDate = this.fromInput.value
        ? new Date(this.fromInput.value)
        : false;
      const toDate = this.toInput.value ? new Date(this.toInput.value) : false;
      const hasModifiedDate = data && data.lastUpdateDatetime ? true : false;

      if (filter == this.filterBlankSecret) {
        filter = "";
      }

      if (filter) {
        for (const key of keys) {
          keywordFound = data[key]
            ? data[key]
                .toString()
                .trim()
                .toLowerCase()
                .indexOf(filter.toLowerCase()) !== -1
            : false;
          if (keywordFound) {
            break;
          }
        }
      }

      if (hasModifiedDate) {
        let modifiedDate = new Date(data.lastUpdateDatetime);
        modifiedDate.setHours(0, 0, 0, 0);
        if (fromDate && toDate) {
          dateFound = modifiedDate >= fromDate && modifiedDate <= toDate;
        } else if (fromDate) {
          dateFound = modifiedDate >= fromDate;
        } else if (toDate) {
          dateFound = modifiedDate <= toDate;
        }
      }

      if (filter && (fromDate || toDate)) {
        return keywordFound && dateFound;
      } else if (filter) {
        return keywordFound;
      } else if (fromDate || toDate) {
        return dateFound;
      } else {
        return true;
      }
    };
  }

  onStatusChange($event: MatSlideToggleChange, documentId: number, element: HTMLInputElement) {
    this.message = null;
    let status : string  = $event.checked ? 'Y': 'N';
    this.documentLibraryService.documentStatusUpdate(documentId, status).pipe(takeUntil(this.unsubscribeAll))
      .subscribe(data => {
        if(data.status == 'error'){
          element.checked = false;
          this.message = data.message;
          window.scroll(0,0);
        }
      });
  }

  openDocument(signedURL : string ) {
      window.open(signedURL);
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
