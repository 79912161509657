import {Component, OnInit, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import { NotificationService } from '../../../../services/notification.service';
import { SpinnerOverlayService } from '../../../../../shared/services/spinner-overlay.service';
import { MatStepper } from '@angular/material';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import { NgForm } from '@angular/forms/forms';
import { NotificationContentMetaData } from '../../../models/notification.model';

@Component({
  selector: 'app-notification-ap-rep',
  templateUrl: './notification-ap-rep.component.html',
  styleUrls: ['./notification-ap-rep.component.scss']
})
export class NotificationApRepComponent implements OnInit, OnDestroy {
  form = {
    content: '',
  };  

  @Input() stepper: MatStepper;
  @Output() unsavedData = new EventEmitter<boolean>();
  private _unsubscribeAll: Subject<any>;
  
  constructor(private notificationService: NotificationService, 
    private spinner: SpinnerOverlayService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.notificationService.currentNotificationBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        data => {
          if(data && data.notificationContentMetaData  && data.notificationContentMetaData.notification ){
            this.form.content = data.notificationContentMetaData.notification.content;
          }
        }, error => {
          // console.log(error);
        }
      );    
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  async save(form: NgForm) {
    if(!form.valid) {
      return;
    }

    this.notificationService.currentNotification.step=2;
    this.notificationService.currentNotification.notificationContentMetaData = { 
      notification: {
        communicationMethod: {
          code: "SMS",
          description: "SMS"
        },          
        content: this.form.content,
        subject: this.notificationService.currentNotification.title
      }
    } as NotificationContentMetaData;

    this.spinner.show();
    const result = await this.notificationService.saveNotification();
    this.spinner.hide();
    if (result) {
      //  save back to currentNotification
      this.notificationService.currentNotification = result;
      this.notificationService.currentNotificationBS.next(result);
      this.stepper.next();
    }
  }

  onChange($event) {
    this.notificationService.setDirty(true);
  }  
}
