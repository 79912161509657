export interface NavigationItem {
    id: string;
    title: string;
    resourceName: string;
    type: 'item' | 'group';
    icon?: string;
    url?: string;
    classes?: string;
    children?: NavigationItem[];
}

export interface Navigation extends NavigationItem {
    children?: NavigationItem[];
}

export const NAVIGATIONROOTDATA: NavigationItem[] = [
    {
        id: 'home',
        title: 'Dashboard',
        resourceName: 'home',
        type: 'item',
        icon: 'i-dashboard',
        url: 'home',
        classes: 'level0'
    },
    {
        id: 'rcc',
        title: 'Rater Comm. Center',
        resourceName: 'comm.center',
        type: 'item',
        icon: 'i-rcc',
        url: 'rcc',
        classes: 'level0'
    },
    {
        id: 'hr',
        title: 'Human Resources Center',
        resourceName: 'hr.center',
        type: 'item',
        icon: 'i-users',
        url: 'hr',
        classes: 'level0'
    }
];
