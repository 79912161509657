import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../core/services/user.service";
import { User } from "../../../_models/user.model";
import { MatDialogRef, MatDialog } from "@angular/material";
import { ConfirmationDialogComponent } from "../../../core/components/shared/confirmation-dialog/confirmation-dialog.component";
import { AuthenticationService } from "../../services/authentication.service";
import { LoginService } from '../../services/login.service';
import { DocumentService } from "../../../core/services/document.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  user: User;
  dialogRef: MatDialogRef<ConfirmationDialogComponent>;
  landingPage: boolean = false;
  //showHeader: boolean = false;

  userLoggedIn: boolean = false;
  internal: boolean = false;
  fileLink: string;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private loginService: LoginService,
    private documentService: DocumentService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.userService.userDataBS.subscribe(userData => {
      if (userData != null && userData.guid != null) {
        this.userLoggedIn = true;
        this.user = userData;
      }
    });
    this.loginService.loginParams.subscribe(data => {
      if (data) {
        this.internal = data.internal;
      }
    });
  }

  showTitle(): boolean {
    if (this.userLoggedIn) {
      return this.internal ? true : false;
    } else if (!this.userLoggedIn) {
      return this.internal ? true : false;
    }
    return false;
  }

  getRole(): string {
    return this.user[0];
  }

  signout() {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "Sign Out",
        includeOkAndCancel: true,
        message: "Are you sure you want to sign out?"
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.user.logonHistoryId) {
          this.userService.logOut(this.user.logonHistoryId).subscribe(data => {
            this.authenticationService.logout();
          }, (error) => {
            // console.log(error);
          });  
        } else {
          this.authenticationService.logout();          
        }
      }
    });
  }

  openLink() {
    this.documentService.getTrainingDocument().subscribe(data => {
      window.open(data.message);
    });    
  }
}