import { Injectable } from '@angular/core';
import { APIService, HTTP_METHODS } from '../../shared/services/APIService.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private apiService: APIService) { }

  getTrainingDocument(): Observable<any> {
    return this.apiService.invokeAPI("repApi", "document/training", {}, HTTP_METHODS.GET);
  }
}