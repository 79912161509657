import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { NotificationService } from '../../../../services/notification.service';
import { SpinnerOverlayService } from '../../../../../shared/services/spinner-overlay.service';
import { MatDatepickerInputEvent, MatStepper } from '@angular/material';
import {Subject} from "rxjs";
import { NgForm } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { DateTime } from 'luxon';
@Component({
  selector: 'app-notification-schedule-ap-rep',
  templateUrl: './notification-schedule-ap-rep.component.html',
  styleUrls: ['./notification-schedule-ap-rep.component.scss']
})
export class NotificationScheduleApRepComponent implements OnInit, OnDestroy {
  form = {
    startDate: new Date(),
    startDateMin: new Date(),
    endDate: new Date(),  
    endDateMin: new Date(),  
    startTime: '',
    endTime: '',  
  };  

  diffDays = 0;
  private EXPIRE_DAYS = 30;
  
  @Input() stepper: MatStepper;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(private notificationService: NotificationService, 
      private spinner: SpinnerOverlayService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.form.startDate = new Date(new Date().setHours(0,0,0,0));
    this.form.startDateMin = this.form.startDate;
    this.form.endDate = new Date(new Date().setHours(0,0,0,0));
    this.form.endDateMin = this.form.startDateMin;
    this.form.endDate.setDate(this.form.endDate.getDate() + this.EXPIRE_DAYS);
    
    this.form.startTime =  (new Date()).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    this.form.endTime = '11:59 PM';

    this.notificationService.currentNotificationBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        data => {
          if (data.repSchedule) {   
            // "2023-01-12 00:01:00" => "01/11/2023 12:01 AM"        
            const startDateTimeText = DateTime.fromJSDate(new Date(data.repSchedule.startDateTime)).toFormat('MM/dd/yyyy hh:mm a');
            const endDateTimeText = DateTime.fromJSDate(new Date(data.repSchedule.endDateTime)).toFormat('MM/dd/yyyy hh:mm a');

            let dateTimeParts = startDateTimeText.split(' ');
            this.form.startDate = new Date(dateTimeParts[0]);
            this.form.startDateMin = new Date(new Date().setHours(0,0,0,0));
            this.form.startTime = `${dateTimeParts[1]} ${dateTimeParts[2]}`;
            
            dateTimeParts = endDateTimeText.split(' ');
            this.form.endDate = new Date(dateTimeParts[0]);
            this.form.endDateMin = this.form.startDateMin;
            this.form.endTime = `${dateTimeParts[1]} ${dateTimeParts[2]}`;
          } 
          this.diffDays = this._calculateDiff(this.form.startDate, this.form.endDate);        
        }, error => {
          // console.log(error);
        }
      );    

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  private _verifyForm(): boolean {
    const startDateTime = new Date(this._combineDateAndTime(this.form.startDate, this.form.startTime));
    const endDateTime = new Date(this._combineDateAndTime(this.form.endDate, this.form.endTime));
    return startDateTime<endDateTime;
  }
  async save(form: NgForm) {
    if(!form.valid) {
      return;
    }    

    if (!this._verifyForm()) {
      return;
    }
    let currentuser = JSON.parse(sessionStorage.getItem('currentUser'));

    this.notificationService.currentNotification.step = 4;
    this.notificationService.currentNotification.scheduledByUserId = currentuser.userId;

    this.notificationService.currentNotification.repSchedule = {
      startDateTime: this._combineDateAndTime(this.form.startDate, this.form.startTime),
      endDateTime: this._combineDateAndTime(this.form.endDate, this.form.endTime),
    };

    this.spinner.show();
    const result = await this.notificationService.saveNotification();
    this.spinner.hide();
    if (result) {
      //  save back to currentNotification
      this.notificationService.currentNotification = result;
      this.notificationService.currentNotificationBS.next(result);
      this.stepper.next();
    }
  }

  private _combineDateAndTime (date: any, time: string): string {
    let jsDate = this._validateDateType(date);
    const year = jsDate.getFullYear();
    const month = jsDate.getMonth() + 1 < 10? '0' + (jsDate.getMonth() + 1) : jsDate.getMonth() + 1;
    const day = jsDate.getDate() < 10? '0' + jsDate.getDate() : jsDate.getDate();
    return `${month}/${day}/${year} ${time}`;
  };  

  startDateChange(event: MatDatepickerInputEvent<Date>) {
    const startDateVal = this._validateDateType(event.value);
    this.diffDays = this._calculateDiff(startDateVal, this.form.endDate);

    this.form.startDateMin = new Date(new Date().setHours(0,0,0,0));
    this.form.endDateMin = startDateVal;

    this.notificationService.setDirty(true);    
  }  
  endDateChange(event: MatDatepickerInputEvent<Date>) {
    const endDateVal = this._validateDateType(event.value);
    this.diffDays = this._calculateDiff(this.form.startDate, endDateVal);

    this.notificationService.setDirty(true);    
  }  
  private _validateDateType(dateVal: any): Date {
    let jsDate = dateVal;
    if ( dateVal._isAMomentObject ) {
      jsDate = new Date(dateVal);
    }

    return jsDate;
  }

  private _calculateDiff(start: Date, end: Date) {
    return Math.round((end.valueOf() - start.valueOf())/(24*60*60*1000));
  }

  onTimeChanged(event){
    this.notificationService.setDirty(true);    
  }  
}
