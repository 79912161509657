import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { LoginService } from './shared/services/login.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './shared/services/authentication.service';
import Amplify from 'aws-amplify';
import { UserService } from './core/services/user.service';
import { LeftNavigationComponent } from './shared/components/left-navigation/left-navigation.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { DateUtils } from "@aws-amplify/core";
import {PermissionsStoreService} from "./_directives/permissions-store.service";





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public headerHeight: number = 0;
  pageTopExtraPadding: number = 20;//Used to add a buffer from the header to the main content
  sidebarVisible: boolean = false;
  isCollapsed: boolean;
  isRedirectedFromIdP: boolean;
  // This is a temp variable should be removed once we are live for external and internal users.
  showExternalLandingPage: boolean;
  isProcessCodeOrError: boolean;
  hasIdpErrorCodes: boolean;
  iDpErrorCode: string;

  @ViewChild(LeftNavigationComponent, {read: LeftNavigationComponent, static: false}) sidebar: LeftNavigationComponent;
  @ViewChild(HeaderComponent, {static: false, read: ElementRef}) headerView: ElementRef;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private renderer: Renderer2,
              private loginService: LoginService,
              private userService: UserService,
              private permissionsStoreService : PermissionsStoreService,
              private http: HttpClient) {
              }


  collapseHandler(isCollapsed){
    this.isCollapsed = isCollapsed;
  }

  isSidebarVisible(){
    setTimeout(()=>{
      if(this.sidebar && this.sidebar.navigationItems.length){
        this.sidebarVisible = true;
      } else{
        this.sidebarVisible = false;
      }
    },0);
  }

  adjustHeaderPadding(){
    //Dynamically gets height of fixed header component and adds padding to accommodate
    const main = document.getElementById('template-content-wrapper');
    if(main && this.headerView && this.headerView.nativeElement){
      this.headerHeight = this.headerView.nativeElement.offsetHeight;
      if(this.sidebarVisible){
        this.headerHeight += this.pageTopExtraPadding;
      }
      this.renderer.setStyle(main, 'padding-top', this.headerHeight + 'px');
    }
  }


  ngOnInit() {

    let CONFIG_URL = 'assets/config.json';
    this.preConfigProcess();
    this.http.get(CONFIG_URL).subscribe(data => {
      this.configEnvironment(data);
      environment.internal = this.isInternal();
      this.showExternalLandingPage = (this.showExternalLandingPage && environment.internal);
      this.loginService.loginParams.next(environment);
      this.configureAmplify();
      this.postConfigProcess();
    },error => {
      // console.log("Unable to get the configuration file.", error);
    });
   // this.router.navigate(['/loading']);
  }

  ngAfterViewChecked(){
    this.isSidebarVisible();
    this.adjustHeaderPadding();
  }

  preConfigProcess() {
    let pathName = window.location.pathname;
    let  urlParams = new URLSearchParams(window.location.search);
    this.isRedirectedFromIdP = (pathName.indexOf('spLoginRedirect') > -1);
    //TODO remove it in phase 2 when both internla and external has to see the same page.
    this.showExternalLandingPage = !(pathName.indexOf('land') > -1);
    this.isProcessCodeOrError = (urlParams.has('code') || urlParams.has('error'));
    if(urlParams.has('p_error_code') || urlParams.has('p_error_codes_list')){
      this.hasIdpErrorCodes=true;
      this.iDpErrorCode = urlParams.get('p_error_code');
    } else if (urlParams.has('locked') && urlParams.get('locked') == 'true') {
      this.hasIdpErrorCodes = true;
      this.iDpErrorCode = 'OAM-5'; //account locked
    }
  }

  postConfigProcess() {

    this.authenticationService.isAuthenticated().subscribe(
      data => {
        if(data) {
          if(sessionStorage.getItem('currentUser') != null){
            let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            this.userService.userDataBS.next(currentUser);
            this.permissionsStoreService.init(currentUser.permissions)
            if(currentUser.internal){
              this.router.navigate(['/core/dashboard']);
             }else {
              this.router.navigate(['/core/preference']);
             }
          }else{
            //TODO Current user is not found. The user might not have logged in properly or his browser might havebeen corrupted.
            this.authenticationService.logout();
          }

        }else{
          //User is not logged in begin Authentication Process
          if (this.isProcessCodeOrError) {
            // 
          } else if(this.isRedirectedFromIdP || this.showExternalLandingPage) {
              // User should be redirected to this URL by IdP for custom login. Do not call federated login again.
              // If the User has book marked this URL he will still go to login page
              // but will see unknown errors after sign in as IdP wouldn't know where to redirect.
              if (this.hasIdpErrorCodes) {
                this.loginService.loginErrors.next(this.loginService.getMessageByCode(this.iDpErrorCode));
              }
               this.router.navigate(['/login']);
          } else {
            this.authenticationService.login();
          }
        }
      },
      error => {
          // console.log("Error checking if the user is authenticated", error);
      }
    );
  }


  configEnvironment(config){
    environment.production = config.production;
    environment.int.hostname = config.int.hostname;
    environment.int.corpUserLoginURL = config.int.corpUserLoginURL;

    environment.int.cognito.userPoolId = config.int.cognito.userPoolId;
    environment.int.cognito.userPoolWebClientId = config.int.cognito.userPoolWebClientId;
    environment.int.cognito.identityPoolId = config.int.cognito.identityPoolId;
    environment.int.cognito.oauth.domain = config.int.cognito.oauth.domain;
    environment.int.cognito.oauth.redirectSignIn = config.int.cognito.oauth.redirectSignIn;
    environment.int.cognito.oauth.redirectSignOut = config.int.cognito.oauth.redirectSignOut;

    environment.ext.idpLoginUrl = config.ext.idpLoginUrl;
    environment.ext.forgotUsernameURL = config.ext.forgotUsernameURL;
    environment.ext.forgotPasswordURL = config.ext.forgotPasswordURL;
    environment.ext.peopleSoftURL = config.ext.peopleSoftURL;
    environment.ext.hostname = config.int.hostname;

    environment.ext.cognito.userPoolId = config.ext.cognito.userPoolId;
    environment.ext.cognito.userPoolWebClientId = config.ext.cognito.userPoolWebClientId;
    environment.ext.cognito.identityPoolId = config.ext.cognito.identityPoolId;
    environment.ext.cognito.oauth.domain = config.ext.cognito.oauth.domain;
    environment.ext.cognito.oauth.redirectSignIn = config.ext.cognito.oauth.redirectSignIn;
    environment.ext.cognito.oauth.redirectSignOut = config.ext.cognito.oauth.redirectSignOut;

    for(var i = 0; i < config.api.endpoints.length; i++) {
      environment.api.endpoints[i].name = config.api.endpoints[i].name;
      environment.api.endpoints[i].endpoint = config.api.endpoints[i].endpoint;
      environment.api.endpoints[i].service = config.api.endpoints[i].service;
      environment.api.endpoints[i].region = config.api.endpoints[i].region;
    }

    environment.s3.bucket = config.s3.bucket;
    //environment.s3.identityPoolId = config.s3.identityPoolId;
  }


  isInternal(): boolean {
    const internal: boolean = (window.location.hostname == environment.int.hostname) ? true : false;
    return internal;
  }


  configureAmplify(): void {
    //TODO get the clock drift from AWS Cognito UserPool user object.
    DateUtils.setClockOffset(0);
    let cognitoOpts: any;
     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
     if(environment.internal) {
      cognitoOpts = environment.int.cognito;
     } else{
      cognitoOpts = environment.ext.cognito;
     }

    let conf =  Amplify.configure({
      Auth: cognitoOpts,
      API: environment.api,
      Storage: environment.s3
    });
}
}
