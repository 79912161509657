import {Component, OnInit, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import {Type, NotificationPreview, NotificationContentMetaData} from '../../../models/notification.model';
import { NotificationService } from '../../../../services/notification.service';
import { SpinnerOverlayService } from '../../../../../shared/services/spinner-overlay.service';
import { MatStepper } from '@angular/material';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-notification-alert',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss']
})
export class NotificationAlertComponent implements OnInit, OnDestroy {

  @Input() stepper: MatStepper;
  @Output() unsavedData = new EventEmitter<boolean>();
  notificationContentMetaDataFormGroup: FormGroup;
  notificationPreviewFormGroup: FormGroup;
  contentPattern = "^ETS MSG: (.|[\n])*";
  mobNumberPattern = new RegExp("^((\\+91-?)|0)?[0-9]{10}$");
  emailPattern= new RegExp(".*(@ets.org)$");
  audioUrl: string;
  currentContentLength: number= 0;
  totalContentLength: number= 153;
  audioAvailable : boolean = false;
  smsMessage: string;
  smsSuccess: boolean;
  emailMessage: string;
  emailSuccess: boolean;

  // Private
  private _unsubscribeAll: Subject<any>;

  // '@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ\x20!"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà\f^{}\\[~]|€'
 GSM_charCodes = [
  10,12,13,32,33,34,35,36,
  37,38,39,40,41,42,43,44,
  45,46,47,48,49,50,51,52,
  53,54,55,56,57,58,59,60,
  61,62,63,64,65,66,67,68,
  69,70,71,72,73,74,75,76,
  77,78,79,80,81,82,83,84,
  85,86,87,88,89,90,91,92,
  93,94,95,97,98,99,100,101,
  102,103,104,105,106,107,108,
  109,110,111,112,113,114,115,
  116,117,118,119,120,121,122,
  123,124,125,126,161,163,164,
  165,167,191,196,197,198,199,
  201,209,214,216,220,223,224,
  228,229,230,232,233,236,241,
  242,246,248,249,252,915,916,
  920,923,926,928,931,934,936,
  937,8364
];

// '\f|^€{}[~]\\'
 GSMe_charCodes = [12,91,92,93,94,123,124,125,126,8364];

  alertMethods: Type[] = [
    {code: "SMS", description: "SMS"},
    {code: "VOICE", description: "Voice"},
    {code: "PREF", description: "Rater Preference"}
  ];

  constructor(private fb: FormBuilder,
              private notificationService: NotificationService, 
              private spinner: SpinnerOverlayService) {
    this._unsubscribeAll = new Subject();
    this.notificationContentMetaDataFormGroup = this.fb.group({
      communicationMethod: [null,Validators.required],
      includeEmailFlag: [true],
      includeBroadCastFlag: [{value: null,disabled: true}],
      content: ["ETS MSG: ", [Validators.required, Validators.pattern(this.contentPattern), Validators.minLength(10)]],
      audioUrl: [null],
      // TODO Move to its own form.
      to: [null],
      from: ["do-not-reply@ets.org"],
      subject: [null],
      message: [null],
      phoneNumber: [null]
    });
  }

  ngOnInit() {
    this.notificationService.currentNotificationBS
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
      data => {
        //TO DO check for alert and message.
        if(data && data.notificationContentMetaData  && data.notificationContentMetaData.alert ){
          this.notificationContentMetaDataFormGroup.patchValue(data.notificationContentMetaData.alert);
          this.checkGsm(this.notificationContentMetaDataFormGroup.get('content').value, null);
          // console.log(this.notificationContentMetaDataFormGroup);
        }
      }, error =>{
        // console.log(error);
      }
    );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(undefined);
    this._unsubscribeAll.complete();
  }

  checkGsm(content: string, event: any) {
    let length = 0;
    if (content) {
      for (var i = 0; i < content.length; i++) {
        if (this.GSMe_charCodes.includes(content.charCodeAt(i))) {
          length += 2;
        } else if (this.GSM_charCodes.includes(content.charCodeAt(i))) {
          length += 1;
        } else {
          this.notificationContentMetaDataFormGroup.get('content').patchValue(content.slice(0, content.length - 1));
          return false;
        }
      }
    }
    if (length > this.totalContentLength) {
      this.notificationContentMetaDataFormGroup.get('content').patchValue(content.slice(0, content.length - 1));
    }
    this.currentContentLength = length;
  }

  resetPreviewForm(){
    this.notificationContentMetaDataFormGroup.get('to').reset();
    this.notificationContentMetaDataFormGroup.get('phoneNumber').reset();
  }

  compareFn(c1: any, c2: any): boolean {
    return JSON.stringify(c1) === JSON.stringify(c2);
  }

  async save(){
    this.resetPreviewForm();
    // console.log(this.notificationContentMetaDataFormGroup.value);
    if (this.notificationContentMetaDataFormGroup.valid) {
        // console.log(this.notificationContentMetaDataFormGroup.value);
        this.notificationService.currentNotification.step=2;
       let notificationContentMetaData = new NotificationContentMetaData();
       this.notificationService.currentNotification.notificationContentMetaData =notificationContentMetaData;
       this.notificationService.currentNotification.notificationContentMetaData.alert= this.notificationContentMetaDataFormGroup.value;
        //Check for multiple spaces. TODO search for regex pattern.
        if(this.notificationService.currentNotification.notificationContentMetaData.alert.content.trim().length <= 8){
          // console.log('User might have entered spaces.');
          this.notificationContentMetaDataFormGroup.get('content').patchValue("ETS MSG: ");
          this.notificationContentMetaDataFormGroup.get('content').setErrors({invalid: true});
          this.notificationContentMetaDataFormGroup.get('content').markAsTouched({onlySelf: true});
          this.checkGsm(this.notificationContentMetaDataFormGroup.get('content').value, null);
          return;
        }
        // console.log('Calling save notification');
        this.spinner.show();
        const result = await this.notificationService.saveNotification();
        this.spinner.hide();
        if (result) {
          //  save back to currentNotification
          this.notificationService.currentNotification = result;
          this.notificationService.currentNotificationBS.next(result);
          this.stepper.next();
        }
     }else {
        //TODO Throw error message. and display it on the screen.
        this.validateAllFormFields(this.notificationContentMetaDataFormGroup)
     }
  }

  previewAudio(){
    if(!this.isContentValid()) {
      return;
    }
    this.notificationContentMetaDataFormGroup.get
      let myPrevButton = document.getElementsByClassName('btnVoicePreview')[0];
      myPrevButton.classList.add('loading');
      myPrevButton.getElementsByClassName('mat-button-wrapper')[0].innerHTML = 'Loading <span class="spinner"></span>';

    // TODO Change the service to accept the content and id.
    this.audioUrl = null;
    this.audioAvailable = false;
    this.notificationService.currentNotification.step=2;
    if (this.notificationService.currentNotification.notificationContentMetaData == null) {
      let notificationContentMetaData = new NotificationContentMetaData();
      this.notificationService.currentNotification.notificationContentMetaData = notificationContentMetaData;
    }
    this.notificationService.currentNotification.notificationContentMetaData.alert = this.notificationContentMetaDataFormGroup.value;
    this.notificationService.previewAudio()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(data => {
       if(data.preSignedUrl != null){
           this.audioUrl = data.preSignedUrl;
           this.audioAvailable = true;
           //Remove the previously assigned loading state of the button
           myPrevButton.classList.remove('loading');
           myPrevButton.getElementsByClassName('mat-button-wrapper')[0].innerHTML = 'Preview Audio';
       }
    }, error =>{
      // console.log("Error in the Step 2", error);
      //TO DO show an error message on the screen;
    });

  }

  isContentValid() : boolean {
       this.notificationContentMetaDataFormGroup.get('content').markAsTouched({ onlySelf: true });
       return this.notificationContentMetaDataFormGroup.get('content').valid;
  }

  onContentEdit() {
    this.audioUrl == null;
    this.audioAvailable = false;
  }

  onNumberEdit() {
    this.smsMessage = null;
  }

  onEmailEdit() {
    this.emailMessage = null;
  }

  sendEmail(){
    this.emailMessage = null;
    let isValid = true;
    if(!this.isContentValid()) {
      isValid = false;
    }
    if(this.notificationContentMetaDataFormGroup.get('to').value == null){
      this.notificationContentMetaDataFormGroup.get('to').setErrors({required: true});
      this.notificationContentMetaDataFormGroup.get('to').markAsTouched({ onlySelf: true });
      isValid = false;
    }else {
      let validationErrors: ValidationErrors = Validators.email(this.notificationContentMetaDataFormGroup.get('to'));
      if(validationErrors != null){
        this.notificationContentMetaDataFormGroup.get('to').setErrors(validationErrors);
        isValid = false;
      } else  if(!this.emailPattern.test(this.notificationContentMetaDataFormGroup.get('to').value)){
        this.notificationContentMetaDataFormGroup.get('to').setErrors({nonets: true});
        isValid = false;
      }
    }
    if(isValid) {
      this.notificationService.sendEmail(this.getNotificationPreviewRequest())
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
          if (data) {
            this.emailMessage = "Email sent successfully";
            this.emailSuccess = true;
          } else {
            this.emailMessage = "Message failed";
            this.emailSuccess = false;
          }
        }, error =>{
          // console.log("Error ",error);
          //TO DO show an error message on the screen;
        });
    }

  }

  sendTextMessage(){
    this.smsMessage = null;
    let isValid = true;
    if(!this.isContentValid()) {
      isValid = false;
    }
   if(!this.mobNumberPattern.test(this.notificationContentMetaDataFormGroup.get('phoneNumber').value)){
        this.notificationContentMetaDataFormGroup.get('phoneNumber').setErrors({invalid: true});
        //This is needed for required (empty or null value)
        this.notificationContentMetaDataFormGroup.get('phoneNumber').markAsTouched({ onlySelf: true });
        isValid = false;
    }

    if (isValid) {
      this.notificationService.sendTextMessage(this.getNotificationPreviewRequest())
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(data => {
          if (data) {
            this.smsMessage = "SMS sent successfully";
            this.smsSuccess = true;
          } else {
            this.smsMessage = "Message failed";
            this.smsSuccess = false;
          }
        }, error => {
          // console.log("Error ", error);
          //TO DO show an error message on the screen;
        });
    }
  }

  //REMOVE this Method once the form is ready.
  getNotificationPreviewRequest(): NotificationPreview{
     let notificationPreview = new NotificationPreview();

    notificationPreview.to = this.notificationContentMetaDataFormGroup.value.to;
    notificationPreview.from = this.notificationContentMetaDataFormGroup.value.from;
    notificationPreview.subject = this.notificationService.currentNotification.title;
    notificationPreview.phoneNumber = this.notificationContentMetaDataFormGroup.value.phoneNumber;
    notificationPreview.message = this.notificationContentMetaDataFormGroup.value.content;

    return notificationPreview;
  }

  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onChange($event) {
    this.notificationService.setDirty(true);
  }  
}
