import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-human-resources-center',
  templateUrl: './human-resources-center.component.html',
  styleUrls: ['./human-resources-center.component.scss']
})
export class HumanResourcesCenterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
